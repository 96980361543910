import React, { PureComponent } from 'react';

class Statistics extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: 0
        }
    }

    totalIncome = (events) => {
        let earnings = events.map(event => Number(event.earned));
        let sum = earnings.reduce((a, b) => { return a + b }, 0);
        return sum;
    }

    eventsNumber = (events) => {
        let eventTypes = [];
        let eventsNumbers = [];
        events.forEach(event => { eventTypes.push(event.type) })
        let uniqueTypes = Array.from(new Set(eventTypes));

        uniqueTypes.forEach(type => {
            let number = 0;
            events.forEach(event => {
                if (event.type === type) {
                    number = number + 1;
                }
            })
            eventsNumbers.push(number);
        })
        let result = uniqueTypes.map((type, i) => `${eventsNumbers[i]} ${type.toLowerCase()} `);
        return result;
    }

    render() {
        const { events } = this.props;
        return (
            <div className='w-100 mt3 bb tc'>
                <h4>TOTAL EARNINGS: {this.totalIncome(events)}</h4>
                <h4>MONTH AVERAGE : {(this.totalIncome(events) / 12).toFixed()} </h4>
                <h4>{events.length} EVENTS: {this.eventsNumber(events)} </h4>
            </div>
        )
    }
}
export default Statistics;