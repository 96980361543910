import React from 'react';

const NavBar = ({ events, teams, income, menu, selected }) => {
    let lightButton = 'ba br3 w-50 tc pa0 ma1 f4 dim pointer bg-dark-gray light-blue white shadow-2';
    let darkButton = 'ba br3 w-50 tc pa0 ma1 f4 dim pointer bg-dark-gray light-blue white shadow-2';
    let selectedButton = 'ba br3 w-50 tc pa0 ma1 f4 dim pointer bg-light-blue white shadow-2';
    let textStyle = 'fw9 ma3 f4-ns f5';
    return (
        <nav className='flex noselect'>
            <div className={selected === 'events' ? selectedButton : lightButton} onClick={events}><div className={textStyle}>EVENTS</div></div>
            <div className={selected === 'money' ? selectedButton : lightButton} onClick={income}><div className={textStyle}>MONEY</div></div>
            <div className={selected === 'teams' ? selectedButton : darkButton} onClick={teams}><div className={textStyle}>TEAMS</div></div>
        </nav>
    )
}
export default NavBar;