import React, { Component } from 'react';

class NoTeam extends Component {
    render() {
        return (
            <div className='w-100 tc pa2'>
                <h2>Create a team:</h2>
                <input type='text' placeholder='team name' required id='createteam'></input>
                <button onClick={this.props.createClick}>Create</button>
            </div>
        )
    }
}
export default NoTeam;