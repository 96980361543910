import React, { PureComponent } from 'react';
import { Line } from 'react-chartjs-2';

const data =
{
    labels: [],
    datasets: [
        {
            label: 'Income',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: []
        }
    ]
}

class YearsChart extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: data,
            reRender: false
        }
    }

    UNSAFE_componentWillReceiveProps() {
        this.setState({ reRender: !this.state.reRender }) //Need This To Update Chart Information in real time
    }

    createData = (events) => {
        function reduceFunction(total, sum) {
            return total + sum;
        }
        let years = [];
        if (!this.props.allYears) {
            events.forEach(event => {
                years.push((new Date(event.date).getFullYear()))
            })
        } else {
            let currentYear = (new Date()).getFullYear();
            events.forEach(event => {
                let yearOfEvent = (new Date(event.date).getFullYear())
                if (yearOfEvent <= currentYear)
                    years.push(yearOfEvent)
            })
        }

        let uniqueYears = Array.from(new Set(years));
        data.labels = uniqueYears;

        data.datasets[0].data = [];
        data.labels.forEach((year, i) => {
            let tempArr = [];
            events.forEach(event => {
                if (year === new Date(event.date).getFullYear()) {
                    tempArr.push(Number(event.earned))
                }
            })
            if (tempArr.length > 0) {
                data.datasets[0].data[i] = tempArr.reduce(reduceFunction, 0);
            } else data.datasets[0].data[i] = 0;
        })
    }

    render() {
        const { events } = this.props;
        const { data } = this.state;
        this.createData(events);
        return (
            <div className='w-100 mt3 bb'>
                <h3 className='tc'>Earning by years</h3>
                <Line
                    height={125}
                    data={data}
                    redraw={false}
                    options={{
                        scales: {
                            yAxes: [{
                                ticks: {
                                    min: 0
                                }
                            }]
                        }
                    }}
                />
            </div>
        )
    }
}
export default YearsChart;