import React from 'react';
import LoginRegister from './LoginRegister';
import ImageBackground from './ImageBackground';
import smallPencilPicture from '../pictures/PencilBackground640x960.jpg';
import bigPencilPicture from '../pictures/PencilsBackground1280x1920.jpg';

const LoginForm = ({ blabla, blabla2, login }) => {
    return (
        <div>
            <LoginRegister clickLogin={blabla} clickRegister={blabla2} />
            <ImageBackground
                imageUrl={window.screen.width >= 992 ? (bigPencilPicture) : (smallPencilPicture)}
                cssClass='imageBackground o-60 h-100 w-100 center'
            />
            <form className="measure center pa1 pa5-ns ma5 ba w-80-ns w-90 shadow-1 ">
                <fieldset id="sign_up" className="ba b--transparent ph0 mh0 ">
                    <legend className="f4 fw6 ph0 mh0 center">Login</legend>

                    <div className="mt3">
                        <label className="db fw6 lh-copy f6" htmlFor="email-address">Email</label>
                        <input
                            className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100"
                            type="email"
                            name="email-address"
                            id="userEmail"
                        />
                    </div>

                    <div className="mv3">
                        <label className="db fw6 lh-copy f6" htmlFor="password">Password</label>
                        <input
                            className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100"
                            type="password"
                            name="password"
                            id="passwordField"
                        />
                    </div>
                </fieldset>
                <div className='center noselect'>
                    <div onClick={login} className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib">Login</div>
                </div>
            </form>
        </div>
    )
}

export default LoginForm;