import React, { Component } from 'react';
import IncomeDiv from './IncomeDiv';

class IncomeList extends Component {
    totalIncome = (events) => {
        let earnings = events.map(event => Number(event.earned));
        let sum = earnings.reduce((a, b) => { return a + b }, 0);
        return sum;
    }

    render() {
        const { eventsByYear, userData, reRender, onSelectedYear, startWorking, showErrors } = this.props;
        return (
            <div className='w-100'>
                <div className='w-100 tc flex bg-dark-gray white'>
                    <div className='w-20 '><i className="fas fa-info pa1" style={{ fontSize: 10 + 'px' }} title='info'></i></div>
                    <div className='w-30'>Date</div>
                    <div className='w-30'>Type</div>
                    <div className='w-20'>Earn</div>
                </div>
                {eventsByYear.map(event =>
                    <IncomeDiv showErrors={showErrors} startWorking={startWorking} event={event} userData={userData} reRender={reRender} key={event._id} onSelectedYear={onSelectedYear} />
                )}
            </div>
        )
    }
}

export default IncomeList;