import React from 'react';

const NewEntry = ({ display, closeDiv, saveEvent, type, date, city, location, price, earnest, description, uid, stateTeam, teams, team }) => {
    let basicStyle = 'newentry ba pa3 o-100 bg-white shadow-1 ma1'
    let displayNone = 'display-none';
    let newEntryStyle = '';

    if (!display) { newEntryStyle = basicStyle.concat(' ', displayNone) } else { newEntryStyle = basicStyle }
    let divStyle = 'flex flex-wrap w-50 ';
    let divStyleDescription = 'flex flex-wrap  w-100 w-60-ns center '
    // let labelStyle = 'ma1 w-100 f3 fw9';
    let inputStyle = 'f3 ma1 ba b--near-white  w-100 bg-dark-gray white hover-bg-black';
    return (
        <div className={newEntryStyle}>
            <div className='pa3 bg-black bg-options w-100 dt'>
                <div className='pl2 pr2 bg-near-white details w-90 center tc  dtc v-mid h-100'>
                    <div className='center h-50'>
                        <form className='flex flex-wrap'>
                            <div className='flex flex-wrap w-100 w-60-ns center'>
                                <div className={divStyle}>
                                    {/* <label className={labelStyle}>TYPE:</label> */}
                                    <input className={inputStyle} required defaultValue={type} id='editType' placeholder='TYPE'></input>
                                </div>
                                <div className={divStyle}>
                                    {/* <label className={labelStyle}>DATE:</label> */}
                                    <input className={inputStyle} type='date' required defaultValue={date} id='editDate' placeholder='LABEL'></input>
                                </div>
                            </div>
                            <div className='flex flex-wrap w-100 w-60-ns center'>
                                <div className={divStyle}>
                                    {/* <label className={labelStyle}>CITY:</label> */}
                                    <input className={inputStyle} type='text' defaultValue={city} id='editCity' placeholder='CITY'></input>
                                </div>
                                <div className={divStyle}>
                                    {/* <label className={labelStyle}>LOCATION:</label> */}
                                    <input className={inputStyle} type='text' defaultValue={location} id='editLocation' placeholder='LOCATION'></input>
                                </div>
                            </div>

                            <div className='flex flex-wrap w-100 w-60-ns center'>
                                <div className={divStyle}>
                                    {/* <label className={labelStyle}>PRICE:</label> */}
                                    <input className={inputStyle} type='number' defaultValue={price} id='editPrice' placeholder='PRICE'></input>
                                </div>
                                <div className={divStyle}>
                                    {/* <label className={labelStyle}>Earnest:</label> */}
                                    <input className={inputStyle} type='number' defaultValue={earnest} id='editEarnest' placeholder='EARNEST'></input>
                                </div>
                            </div>

                            <div className={divStyleDescription}>
                                {/* <label className={labelStyle}>Description:</label> */}
                                <textarea className={inputStyle} defaultValue={description} id='editDescription' placeholder='DESCRIPTION'></textarea>
                            </div>
                            <div className=' flex flex-wrap w-50 center' >
                                <select id='selectEditEntry' name="teams" defaultValue={team} className='tc dt pa1 ma1 w-50 f5 dim pointer noselect shadow-1 fw9 center bg-dark-gray white' title={stateTeam !== 'allteams' ? "Team already selected !" : 'Select team'} >
                                    {teams.map(team => {
                                        return <option value={team._id} key={team._id} >{team.teamName} </option>
                                    })}
                                </select>
                            </div>

                            <div className='flex w-100 center ma1'>
                                <div className='ba br3 w-20-ns w-30-m w-40 tc pa1 ma3 f4 dim pointer center noselect shadow-1 fw9' onClick={saveEvent}
                                    uid={uid}
                                >Save Changes</div>
                                <div className='ba br3 w-20-ns w-30-m w-40 tc pa1 ma3 f4 dim pointer center noselect shadow-4 fw9' onClick={closeDiv}>Close</div>

                            </div>
                        </form>
                    </div>
                    <div className='h-50'>   </div>
                </div>
            </div>
        </div>
    )
}
export default NewEntry;