import React, { Component } from 'react';

class ProfileTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            st: 'st'
        }
    }

    render() {
        let lightTab = 'ba br3 w-100 tc pa0 ma1 f4 dim pointer bg-light-blue white shadow-3 h4 dt noselect';
        const { profile } = this.props;

        return (
            <div className='w-100' onClick={profile}>
                <div className=''>
                    <div className={lightTab} ><div className='fw9 ma3 dtc v-mid f4-ns f5'>PROFILE</div></div>
                </div>

            </div>
        )
    }
}
export default ProfileTab;