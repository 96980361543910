// eslint-disable-next-line
import { editUserName, editUserRole, removeTeamMember, addEvent, removeEvent, editEvent, addRecurrent, removeRecurrent, addEarning, addEarnValue, editEarning, removeEarning, addTeam, editTeamName, removeTeam } from '../queries/queries';
import userData from '../components/UserData';
import { db, tempDb } from '../queries/db';
import { emitData } from '../queries/socket';

const checkAndSaveTemporaryData = async () => {
    let tempAddedEvents = await tempDb.addedEvents.toArray();
    // eslint-disable-next-line
    let tempAddedEarnings = await tempDb.addedEarnings.toArray();
    let tempAddedTeams = await tempDb.addedTeams.toArray();
    let tempEditedEvents = await tempDb.editedEvents.toArray();
    let tempEditedEarnings = await tempDb.editedEarnings.toArray();
    let tempEditedTeams = await tempDb.editedTeams.toArray();
    let tempEditedUser = await tempDb.editedUser.toArray();
    let tempRemovedEvents = await tempDb.removedEvents.toArray();
    let tempRemovedEarnings = await tempDb.removedEarnings.toArray();
    let tempRemovedTeams = await tempDb.removedTeams.toArray();

    // console.log(tempAddedEvents, tempAddedEarnings, tempAddedTeams, tempEditedEvents, tempEditedEarnings, tempEditedTeams, tempEditedUser, tempRemovedEvents,
    //     tempRemovedEarnings, tempRemovedTeams);

    if (tempAddedTeams.length > 0) {
        window.myApp.startWorking(true, 'FOUND UNSAVED TEAMS. SAVING...');
        for (let team of tempAddedTeams) {
            let { _id, teamName, owner } = team;
            let savedTeam = await addTeam(teamName, owner, userData.user.authToken);
            if (savedTeam) {
                tempAddedEvents.forEach(event => {
                    if (event.team === _id) {
                        event.team = savedTeam.data.addTeam._id;
                    }
                })
                await tempDb.addedTeams.delete(_id);
                await db.teams.put(savedTeam.data.addTeam);
                userData.realTeams.push(savedTeam.data.addTeam);
            } else {
                console.log('FAILED !');
            }
        }
        window.myApp.startWorking(false);
    }

    if (tempAddedEvents.length > 0) {
        window.myApp.startWorking(true, 'SAVING...');
        for (let event of tempAddedEvents) {
            let { _id, type, date, location, city, price, earnest, description, team, created, earned, owner, author, authorEmail, editAuthor, editAuthorEmail, modified } = event;
            let savedEvent = await addEvent(type, date, team, owner, location, city, price, earned, earnest, description, created, author, authorEmail, editAuthor, editAuthorEmail, modified, userData.user.authToken).catch(err => console.log('token: ', userData.user.authToken, 'heere: ', err));
            if (savedEvent) {
                await tempDb.addedEvents.delete(_id);
                await db.events.put(savedEvent.data.addEvent);
                userData.realEvents.push(savedEvent.data.addEvent);
                emitData('addEvent', savedEvent.data.addEvent);
            } else {
                console.log('FAILED !');
            }
        }
        window.myApp.startWorking(false);
    }
    // if (tempAddedEarnings.length > 0) {
    //     window.myApp.startWorking(true, 'FOUND UNSAVED EARNINGS . SAVING...');
    //     for (let earning of tempAddedEarnings) {
    //         let { _id, type, date, location, city, price, earnest, description, team, earned, owner } = earning;
    //         let savedEarning = await addEarning(type, date, team, owner, location, city, price, earned, earnest, description, userData.user.authToken);
    //         if (savedEarning) {
    //             await tempDb.addedEarnings.delete(_id);
    //             await db.earnings.put(savedEarning.data.addEarning);
    //             userData.realEvents.push(savedEarning.data.addEarning);
    //         } else {
    //             console.log('FAILED !');
    //         }
    //     }
    //     window.myApp.startWorking(false);
    // }

    if (tempEditedEvents.length > 0) {
        window.myApp.startWorking(true, 'Saving...');
        for (let event of tempEditedEvents) {
            let { _id, type, date, location, city, price, earnest, description, team, owner, authorEmail, editAuthor, editAuthorEmail, modified } = event;
            let revertId = _id.split('temp-edited-'); //REMOVE THE 'temp-edited-' PREFIX FROM ID
            let editedEvent = await editEvent(revertId[1], type, date, team, owner, location, city, price, earnest, description, authorEmail, editAuthor, editAuthorEmail, modified, userData.user.authToken);

            if (editedEvent) {
                await tempDb.editedEvents.delete(_id);
                await db.events.put(editedEvent.data.editEvent);
                userData.realEvents.forEach((ev, index) => {
                    if (ev._id === revertId[1]) {
                        userData.realEvents.splice(index, 1);
                        userData.realEvents.push(editedEvent.data.editEvent);
                    }
                })

                emitData('editEvent', editedEvent.data.editEvent);
            } else {
                console.log('FAILED !');
            }
        }
        window.myApp.startWorking(false);
    }
    if (tempEditedEarnings.length > 0) {

    }
    if (tempEditedTeams.length > 0) {

    }
    if (tempEditedUser.length > 0) {

    }
    if (tempRemovedEvents.length > 0) {
        for (let event of tempRemovedEvents) {
            let { _id, team } = event;
            let removedEventId = await removeEvent(userData.user.email, _id, team, userData.user.authToken).catch(async err => {
                if (err.message === 'GraphQL error: Argument passed in must be a single String of 12 bytes or a string of 24 hex characters') {
                    await tempDb.removedEvents.delete(_id);
                }
            });
            if (removedEventId) {
                tempDb.removedEvents.delete(_id);
                db.events.delete(_id);
                userData.realEvents.forEach((ev, index) => {
                    if (ev._id === event._id) {
                        userData.realEvents.splice(index, 1);
                    }
                });
                emitData('removeEvent', {
                    event: removedEventId.data.removeEvent,
                    requester: userData.user
                });
            }
        }
    }
    if (tempRemovedEarnings.length > 0) {

    }
    if (tempRemovedTeams.length > 0) {

    }
}

export { checkAndSaveTemporaryData };