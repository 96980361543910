import React, { Component } from 'react';
import './App.css';
import HeaderDb from './components/Header';
import Home from './components/Home';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import Navigation from './components/Navigation';
import NoTeam from './components/NoTeam';
import userRealData from './components/UserData';
import userDemoData from './components/UserDemoData';
import Users from './components/Users';
import { ApolloProvider } from '@apollo/react-hooks';
import Working from '../src/screens/Working';
import { client, getUser, getUserEvents, getUserTeams, addTeam, addUser, generateJWT } from '../src/queries/queries';
import { connectSocket, disconnectSocket, emitData } from '../src/queries/socket';
import { db, createRealDB, removeRealDB, tempDb } from '../src/queries/db';
import { addTempTeam } from '../src/queries/temporary';
import { checkAndSaveTemporaryData } from '../src/queries/saveTemporary';
import ShowError from './screens/Errors';
import ShowConfirmation from './screens/Confirmations';
let userData = [];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: 'login',
      demo: false,
      isLogged: false,
      name: null,
      navigationRoute: 'events',
      navigationKey: false,
      working: false,
      workingMessage: '',
      showError: false,
      showErrorMessage: null,
      showConfirmation: false,
      confirmationMessage: null,
      statusMessage: '',
      update: false
    }
  }

  dataSelect = () => {
    this.state.demo ? (userData = userDemoData) : (userData = userRealData)
  }

  onLoginClick = () => {
    console.log('login clicked');
    this.setState({
      route: 'login',
    })
  }

  onRegisterClick = () => {
    console.log('register clicked');
    this.setState({
      route: 'register'
    })
  }

  onHeaderClick = () => {
    const { isLogged } = this.state;
    console.log('header clicked');
    isLogged ? (this.setState({
      route: 'navigation',
    })) : (this.setState({
      route: 'home',
    }))
  }
  //FUNCTION FOR DISPLAY STATUS:
  startWorking = (boolean, message) => {
    if (boolean) {
      this.setState({
        working: true,
        workingMessage: message
      });
    } else {
      this.setState({
        working: false,
        workingMessage: ''
      })
    }
  }
  //FUNCTION FOR DISPLAY CONFIRMATION
  showConfirmation = (boolean, message) => {
    if (boolean) {
      this.setState({
        showConfirmation: true,
        confirmationMessage: message
      });
    } else {
      this.setState({
        showConfirmation: false,
        confirmationMessage: ''
      })
    }
  }

  //FUNCTION FOR DISPLAY ERRORS:
  showErrors = (boolean, arr) => {
    if (boolean) {
      this.setState({
        showErrorMessage: arr,
        showError: true
      })
    } else {
      this.setState({
        showError: false,
        showErrorMessage: null
      })
    }
  }

  onErrorOkClick = () => {
    this.setState({ showError: false, showErrorMessage: null })
  }

  onConfirmationOkClick = () => {
    this.setState({ showConfirmation: false, confirmationMessage: '' })
  }

  onLoginSubmit = async () => {
    let userEmail = document.getElementById('userEmail').value.trim().toLowerCase();
    let userPassword = document.getElementById('passwordField').value.trim();

    if (userEmail) {
      if (userPassword) {
        db.open();
        tempDb.open();
        let datab = await db.user.toArray();
        if (datab.length === 0) { //IF NO OTHER USER IS LOGGED FROM DEVICE
          let correctCredentials = await this.authorizeUser(userEmail, userPassword);
          if (correctCredentials) {
            this.startWorking(true, 'Loading your data...');
            await this.receiveUserData(userEmail, 'events'); //events FOR SETTING NAVIGATION ROUTE
            // await createRealDB(userRealData.user, userRealData.realEvents, userRealData.realTeams, userRealData.realRecurrent, userRealData.realEarnings);
            localStorage.setItem('isLogged', 'true');
          }
        } else {
          if (datab[0].email === userEmail) { //IF EMAIL IS THE SAME WITH THE LOGGED IN USER
            let correctCredentials = await this.authorizeUser(userEmail, userPassword);
            if (correctCredentials) {
              window.location.reload(); //RELOAD PAGE FOR AUTO-LOGIN THE LOGGED IN USER
            }
          } else {
            this.showErrors(true, ['Another user is already logged in from this device. PLEASE REFRESH'])
          }
        }

      }
    }
  }

  authorizeUser = async (userEmail, userPassword) => {
    this.startWorking(true, 'Checking your identity...');
    let anyError = [];
    let token = await generateJWT(userEmail, userPassword).catch(err => { anyError[0] = { credentialError: err } });
    if (token) {
      userData.user.authToken = token.data.generateJWT;
      return true;
    } else {
      this.startWorking(false);
      anyError.forEach(err => {
        if (err.credentialError.networkError) {
          this.showErrors(true, ['Network Error ! Please try again later !']);
        } else {
          this.showErrors(true, ['Wrong Credentials (email/password)!']);
        }
      })
      return false;
    }
  }

  setStatusMessage = (message) => {
    // console.log('statusmessage: ', message)
    this.setState({ statusMessage: message })
  }

  receiveUserData = async (userEmail, navigationRoute) => {
    if (userEmail) {
      // this.startWorking(true, 'Loading user data...');
      this.setStatusMessage('updating...');
      let anyError = [];
      let authToken = userData.user.authToken;
      let UserResult = await getUser(userEmail, authToken).catch(err => { anyError[0] = { getUserError: err } });
      if (UserResult) {
        let UserEventsResult = await getUserEvents(userEmail, authToken).catch(err => anyError[1] = { getUserEventsError: err });
        if (!(UserEventsResult.getUserEventsError instanceof Error) && UserEventsResult) {
          let UserTeamsResult = await getUserTeams(userEmail, authToken).catch(err => anyError[2] = { getUserTeamsError: err });
          if (!(UserTeamsResult.getUserTeamsError instanceof Error) && UserTeamsResult) {
            const { _id, name, email, recurrent, earnings, role } = UserResult.data.user;
            const events = UserEventsResult.data.getUserEvents;
            const teams = UserTeamsResult.data.getUserTeams;
            userRealData.user.id = _id;
            userRealData.user.name = name;
            userRealData.user.email = email;
            userRealData.user.role = role;
            userRealData.realTeams = teams;
            userRealData.realRecurrent = recurrent;
            userRealData.realEarnings = earnings;
            userRealData.realEvents = events;
            await createRealDB(userRealData.user, events, teams, recurrent, earnings);

            this.setState({
              route: 'navigation',
              navigationRoute: navigationRoute,
              isLogged: true
            })

            this.setStatusMessage('updated');
            connectSocket();
            await checkAndSaveTemporaryData();

            this.startWorking(false);
          } else {
            this.startWorking(false);
            anyError.forEach(err => {
              this.showErrors(true, ['Acces Denied !'])
            })
          }
        } else {
          this.startWorking(false);
          anyError.forEach(err => {
            if (err.getUserEventsError.graphQLErrors[0].message) {
              if (err.getUserEventsError.graphQLErrors[0].message === 'jwt expired') {
                this.showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
                this.onExitClick();
              }
            } else {
              this.showErrors(true, ['Acces Denied !'])
            }
          })
        }
      } else {
        this.startWorking(false);
        let errorMessages = [];

        for (let err of anyError) {
          // console.log('err: ', err);
          this.setStatusMessage('OFFLINE MODE');
          if (err.getUserError.networkError) {

            let eventsFromIndexDB = await db.events.toArray();
            let earningsFromIndexDB = await db.earnings.toArray();
            let recurrentFromIndexDB = await db.recurrent.toArray();
            let teamsFromIndexDB = await db.teams.toArray();

            //ADDING DATA FROM TempDB:
            let tempEvents = await tempDb.addedEvents.toArray();
            let editedEvents = await tempDb.editedEvents.toArray();
            tempEvents.forEach(event => { eventsFromIndexDB.push(event) });
            editedEvents.forEach(event => { eventsFromIndexDB.push(event) });
            //.......................

            userData.realEarnings = earningsFromIndexDB;
            userData.realEvents = eventsFromIndexDB;
            userData.realRecurrent = recurrentFromIndexDB;
            userData.realTeams = teamsFromIndexDB;
            // this.showErrors(true, ['Network Error ! For now you will see your OFFLINE EVENTS ONLY !']);
            this.setStatusMessage('OFFLINE MODE');
          } else {
            if (err.getUserError.message) {
              if (err.getUserError.message === 'GraphQL error: jwt expired') {
                this.showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
                this.onExitClick();
                this.setState({ route: 'login' })
              }
            }
            else {
              errorMessages.push(err.getUserError.message); console.log('error heere')
            }
          }
        }
        if (errorMessages.length) { this.showErrors(true, errorMessages) }
      }
    }
  }

  onRegisterSubmit = async () => {
    let userNameField = document.getElementById('registerUser');
    let emailField = document.getElementById('registerEmail');
    let roleField = document.getElementById('registerRole');
    let password1Field = document.getElementById('registerPassword1');
    let password2Field = document.getElementById('registerPassword2');

    if (userNameField.value && emailField.value && roleField.value && password1Field.value && password2Field.value) {
      [userNameField, emailField, roleField, password1Field, password2Field].forEach(field => { field.setAttribute('style', 'border-color: inherit'); });
      if (emailField.checkValidity()) {
        if (password1Field.value.trim() === password2Field.value.trim()) {
          this.startWorking(true, 'Creating User...');
          let anyError = [];
          let newUser = await addUser(userNameField.value.trim(), emailField.value.trim().toLowerCase(), password2Field.value.trim(), roleField.value.trim()).catch(err => { anyError[0] = err });
          if (newUser) {
            let correctCredentials = await this.authorizeUser(emailField.value.trim().toLowerCase(), password2Field.value.trim());
            if (correctCredentials) {
              await this.receiveUserData(emailField.value.trim().toLowerCase(), 'teams'); //'teams' FOR SETTING NAVIGATION ROUTE
              await createRealDB(userRealData.user, userRealData.realEvents, userRealData.realTeams, userRealData.realRecurrent, userRealData.realEarnings);
              // let localIsLoggedKey = userData.user.name.concat('IsLogged');
              // localStorage.setItem(localIsLoggedKey, 'true');
              localStorage.setItem('isLogged', 'true');

              this.startWorking(false);
            }
          } else {
            this.startWorking(false);
            if (anyError[0].networkError) {
              this.showErrors(true, ['Network Error ! Please try again later!'])
            } else {
              if (anyError[0].graphQLErrors[0].message) {
                this.showErrors(true, [anyError[0].graphQLErrors[0].message])
              } else { this.showErrors(true, ['Sorry, unknown error ! Please try again later!']) }
            }
            await client.resetStore()
          }
        } else {
          password1Field.setAttribute('style', 'border-color: red');
          password2Field.setAttribute('style', 'border-color: red');
          this.showErrors(true, ['Passwords are different! Confirm the same password!']);
        }
      } else {
        emailField.setAttribute('style', 'border-color: red');
        this.showErrors(true, ['Invaild email adress !']);
      }
    } else {
      [userNameField, emailField, roleField, password1Field, password2Field].forEach(field => {
        if (!field.value) {
          field.setAttribute('style', 'border-color: red');
        } else {
          field.setAttribute('style', 'border-color: inherit');
        }
      })
    }
  }
  onExitClick = async (fromUser) => { //THE fromUser PARAMETER SHOULD BE true IF METHOD IS CALLED AUTO FROM SOCKET OR FALSE IF USER LOG'S OUT MANUALY
    this.startWorking(true, 'See you later...');
    console.log('exit clicked');
    await client.resetStore()
    this.setState({
      route: 'home',
      isLogged: false
    })
    this.startWorking(false);
    if (!fromUser) { //CHECKING IF METHOD IS CALLED AUTO FROM SOCKET OR USER CLICKED IT
      await removeRealDB();
      localStorage.setItem('isLogged', 'false');

    }
    disconnectSocket();
    tempDb.close();
  }

  onCreateClick = async () => {
    console.log('create team clicked');
    let firstTeam = document.getElementById('createteam');
    let authToken = userData.user.authToken;
    if (firstTeam.value.length > 0) {
      this.startWorking(true, `Creating ${firstTeam.value.trim()}...`);
      let newTeam = await addTeam(firstTeam.value.trim(), userData.user.email, authToken).catch(err => { console.log(err) });
      if (newTeam) {
        userData.realTeams.push(newTeam.data.addTeam);
        await db.teams.put(newTeam.data.addTeam);
        this.setState({
          route: 'navigation'
        });
        emitData('addNewTeam', {
          team: newTeam.data.addTeam,
          requester: userData.user
        });
        this.startWorking(false);
      } else {
        this.startWorking(false);
        this.showErrors(true, ["Network Error! Can't add a team right now. Try again later!"]);
      }
    }
    else {
      firstTeam.setAttribute('style', 'border-color: red');
    }
  }

  addNewTeam = async () => {
    let newTeam = document.getElementById('newteam');
    let authToken = userData.user.authToken;
    if (newTeam.value.length > 0) {
      this.startWorking(true, 'Creating team...');
      let team = await addTeam(newTeam.value.trim(), userData.user.email, authToken).catch(err => {
        console.log(err);
        if (err.message === 'GraphQL error: jwt expired') {
          this.startWorking(false);
          this.showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
          this.onExitClick();
        } else {
          this.startWorking(false);
          addTempTeam(newTeam.value.trim());
          this.showErrors(true, ['ADDING TEAM FAILED. PLEASE TRY AGAIN LATER']);
          console.log(err.message);
        }
      });
      if (team) {
        userData.realTeams.push(team.data.addTeam);
        await db.teams.put(team.data.addTeam);
        this.setState({
          route: 'navigation'
        });
        emitData('addNewTeam', {
          team: team.data.addTeam,
          requester: userData.user
        });
        this.startWorking(false);
      }
      newTeam.value = '';
    }
  }

  onDemoClick = () => {
    console.log('Demo Cliked');
    this.setState({ demo: !this.state.demo, route: 'navigation' })
  }
  onUserNameChange = () => {
    this.setState({ name: userData.user.name });
  }
  //For Navigation:
  onEventsClick = () => {
    this.setState({
      navigationRoute: 'events',
      navigationKey: !this.state.navigationKey
    });
  }
  onTeamsClick = () => {
    this.setState({
      navigationRoute: 'teams',
      navigationKey: !this.state.navigationKey
    });
  }
  onMoneyClick = () => {
    this.setState({
      navigationRoute: 'money',
      navigationKey: !this.state.navigationKey
    });
  }
  onMenuClick = () => {
    this.setState({
      navigationRoute: 'menu',
      navigationKey: !this.state.navigationKey
    });
  }
  //......................................................

  switchState = (type) => {
    switch (type) {
      case 'home': return <Home blabla={this.onLoginClick} blabla2={this.onRegisterClick} />;
      case 'login': return <LoginForm blabla={this.onLoginClick} blabla2={this.onRegisterClick} login={this.onLoginSubmit} />;
      case 'register': return <RegisterForm blabla={this.onLoginClick} blabla2={this.onRegisterClick} onRegisterSubmit={this.onRegisterSubmit} />;
      case 'noteam': return <NoTeam createClick={this.onCreateClick} />
      case 'navigation': return <Navigation showConfirmation={this.showConfirmation} showErrors={this.showErrors} startWorking={this.startWorking} onEventsClick={this.onEventsClick} onMoneyClick={this.onMoneyClick} onTeamsClick={this.onTeamsClick} onMenuClick={this.onMenuClick} exit={() => { this.onExitClick(false) }} userData={userData} addNewTeam={this.addNewTeam} onLoginSubmit={this.onLoginSubmit} users={Users} demo={this.onDemoClick} demoState={this.state.demo} onUserNameChange={this.onUserNameChange} createClick={this.onCreateClick} newRoute={this.state.navigationRoute} key={this.state.navigationKey} />
      default: return <p>No Route selected</p>
    }
  }

  updateApp = (msg) => {
    if (msg) {
      this.setState({ showConfirmation: true, confirmationMessage: msg });

    } else {
      this.setState({ update: !this.state.update });
    }
  }
  //THIS CODE WILL MAKE APP PERSIST STATE ON PAGE REFRESH:

  saveDataBeforePageRefresh = () => {
    if (this.state.isLogged) {//SAVE USER'S DATA IN SESSION STORAGE BEFORE UNLOAD (PAGE REFRESH) FOR FUTURE RECOVERY
      window.addEventListener('beforeunload', this.saveDataInSessionStorage);
    } else {// DON'T SAVE USER'S DATA IN SESSION STORAGE BEFORE UNLOAD (PAGE REFRESH) FOR FUTURE RECOVERY
      window.removeEventListener('beforeunload', this.saveDataInSessionStorage);
    }
  }
  saveDataInSessionStorage = async () => { //SAVE DATA METHOD FOR FUTURE USE
    if (JSON.parse(localStorage.getItem('isLogged'))) {
      sessionStorage.setItem('data', JSON.stringify(userData));
      sessionStorage.setItem('navRoute', this.state.navigationRoute);
      disconnectSocket();
    }

  }

  recoverDataAfterPageRefresh = () => {
    if (sessionStorage.getItem('data') && sessionStorage.getItem('navRoute')) {
      let isLogged = JSON.parse(localStorage.getItem('isLogged'));
      if (isLogged) { //IF USER IS LOGGED IN
        //TEMPORARY RETRIEVE DATA FROM SESSION STORAGE BEFORE GETTING DATA FROM DATABASE
        const { _id, name, email, role, authToken } = JSON.parse(sessionStorage.getItem('data')).user;
        const events = JSON.parse(sessionStorage.getItem('data')).realEvents;
        const teams = JSON.parse(sessionStorage.getItem('data')).realTeams;
        const earnings = JSON.parse(sessionStorage.getItem('data')).realEarnings;
        const recurrent = JSON.parse(sessionStorage.getItem('data')).realRecurrent;
        const navRoute = sessionStorage.getItem('navRoute');
        userRealData.user.id = _id;
        userRealData.user.name = name;
        userRealData.user.email = email;
        userRealData.user.role = role;
        userData.user.authToken = authToken;
        userRealData.realTeams = teams;
        userRealData.realRecurrent = recurrent;
        userRealData.realEarnings = earnings;
        userRealData.realEvents = events;
        this.setState({ isLogged: true, route: 'navigation', navigationRoute: navRoute });
        //RETRIEVE REAL DATA FROM DATABASE
        // this.receiveUserData(email, navRoute);
        //DESTROY DATA FROM SESSION STORAGE AFTER RECOVERY (SECURITY REASONS)
        sessionStorage.removeItem('data');
        sessionStorage.removeItem('navRoute');
        // sessionStorage.removeItem('isLogged');

      } else { //IF USER IS LOGGED OUT

        // this.onExitClick(true);
        this.setState({
          isLogged: false,
          route: 'login'
        })
      }
    }
  }
  //.............................................................................................
  //THIS CODE WILL MAKE APP PERSIST DATA IN INDEXED DB WHILE OFFLINE:
  checkIfUserIsLoggedIn = async () => {
    let isLoggedIn = JSON.parse(localStorage.getItem('isLogged'));
    if (isLoggedIn && !this.state.isLogged) {
      this.setState({
        isLogged: true,
        route: 'navigation'
      })
    }
  }

  getIndexedDbBeforeMount = async () => {
    let isLoggedIn = JSON.parse(localStorage.getItem('isLogged'));

    if (isLoggedIn && !this.state.isLogged) {
      let userFromIndexDB = await db.user.toArray();
      let eventsFromIndexDB = await db.events.toArray();
      let earningsFromIndexDB = await db.earnings.toArray();
      let recurrentFromIndexDB = await db.recurrent.toArray();
      let teamsFromIndexDB = await db.teams.toArray();

      //ADDING DATA FROM TempDB:
      let tempEvents = await tempDb.addedEvents.toArray();
      let editedEvents = await tempDb.editedEvents.toArray();
      tempEvents.forEach(event => { eventsFromIndexDB.push(event) });
      editedEvents.forEach(event => { eventsFromIndexDB.push(event) });
      //.......................

      userData.realTeams = teamsFromIndexDB;
      userData.user = userFromIndexDB[0];
      userData.realEarnings = earningsFromIndexDB;
      userData.realEvents = eventsFromIndexDB;
      userData.realRecurrent = recurrentFromIndexDB;
      await this.receiveUserData(userData.user.email, 'events');

      this.setState({ update: !this.state.update });
    }
  }
  //.............................................................................................
  UNSAFE_componentWillMount() {
    this.dataSelect(); //THIS METHOD WILL DECIDE IF DATA IS DEMO OR REAL BEFORE DATA LOAD
    this.getIndexedDbBeforeMount(); //THIS METHOD WILL RETRIEVE DATA STORED IN IndexedDB AND STORE IT INTO userData BEFORE APP MOUNTS
  }

  componentDidMount() {
    this.recoverDataAfterPageRefresh()// THIS METHOD WILL RECOVER USER'S DATA FROM SESSION STORAGE AFTER PAGE REFRESH
    this.checkIfUserIsLoggedIn(); //THIS METHOD WILL CHECK IF USER IS LOGGED IN AND CHANGE STATE: Route: Navigation AND isLogged: true IF IS
  }


  render() {
    const { route, isLogged, working, workingMessage, showError, showErrorMessage, showConfirmation, confirmationMessage, statusMessage } = this.state;
    this.saveDataBeforePageRefresh(); //THIS METHOD WILL TEMPORARY SAVE DATA INTO SESSION STORAGE BEFORE PAGE UNLOAD (PAGE REFRESH)

    return (
      <ApolloProvider client={client}>
        <main className='min-vh-100'>
          <HeaderDb headerClick={this.onHeaderClick} isLogged={isLogged} name={userData.user.name} menu={this.onMenuClick} statusMessage={statusMessage} />
          <article className="relative center w-100 w-90-m w-80-l  baskerville min-vh-100 ">
            {this.switchState(route)}
          </article>
          {working ? (<Working message={workingMessage} />) : (null)}
          {showError ? (<ShowError onOk={this.onErrorOkClick} arr={showErrorMessage} />) : (null)}
          {showConfirmation ? (<ShowConfirmation onOk={this.onConfirmationOkClick} msg={confirmationMessage} />) : null}
        </main>
      </ApolloProvider>
    );
  }
}

export default App;