import React, { Component } from 'react';
import ProfileTab from './ProfileTab';
import ImageBackground from '../ImageBackground';
// eslint-disable-next-line
import DemoTab from './DemoTab'
import UserProfile from './UserProfile';
import smallPencilPicture from '../../pictures/PencilBackground640x960.jpg';
import bigPencilPicture from '../../pictures/PencilsBackground1280x1920.jpg';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            route: 'menu', //this should be 'menu'
            // reRender: false
        }
    }

    onProfileClick = () => {
        this.setState({
            route: 'profile'
        })
    }

    switchState = (type) => {
        switch (type) {
            case 'profile': return <UserProfile showErrors={this.props.showErrors} startWorking={this.props.startWorking} userData={this.props.userData} onUserNameChange={this.props.onUserNameChange} exit={this.props.exit} />
            default: return <p>No Navigation Route selected</p>
        }
    }

    render() {
        // eslint-disable-next-line
        const { exit, demo } = this.props;
        const { route } = this.state;
        let exitButton = 'ba br3 w-100 tc pa0 ma1 f4 dim pointer bg-gray white shadow-3 h4 dt noselect';
        return (
            <div className='vh-100'>
                {route === 'menu' ? (
                    <div className='flex flex-wrap w-50-ns center ma3'>
                        {
                        // eslint-disable-next-line
                        /* <DemoTab demo={demo} demoState={this.props.demoState} /> */
                        }
                        <ProfileTab profile={this.onProfileClick} />
                        <div className=' w-100 '>
                            <div className={exitButton} onClick={exit}><div className='fw1 ma3 dtc v-mid f4-ns f5'>LOG OUT</div></div>
                        </div>
                    </div>
                ) : (this.switchState(route))}
                <ImageBackground
                imageUrl={window.screen.width >= 992 ? (bigPencilPicture) : (smallPencilPicture)}
                cssClass='imageBackground o-90 h-100 w-100 center'
                />
            </div>
        )
    }
}
export default Menu;