import io from 'socket.io-client';
import userData from '../components/UserData';
import { db, createRealDB } from '../queries/db';
// eslint-disable-next-line
import { checkAndSaveTemporaryData } from '../queries/saveTemporary';

let socket = [];
let socketURL = process.env.REACT_APP_LOCAL_SOCKET ? (process.env.REACT_APP_LOCAL_SOCKET) : ('sleepy-dawn-33746.herokuapp.com');


const connectSocket = () => {
    socket[0] = io.connect(socketURL, {
        secure: true,
        reconnect: true,
        rejectUnauthorized: false,
        query: { token: userData.user.authToken }
    });

    socket[0].on('connect', async () => {
        // await checkAndSaveTemporaryData();
        let teamsIds = userData.realTeams.map(team => { return team._id });
        socket[0].emit('userConnect', {
            email: userData.user.email,
            rooms: teamsIds,
        })
        for (let id of teamsIds) {
            let teamEvents = userData.realEvents.filter(event => { return event.team === id });
            let team = userData.realTeams.filter(team => { return team._id === id });
            emitData('joinTeam', {
                teamId: id,
                events: teamEvents,
                team: team[0]
            });
        }
        window.myApp.setStatusMessage('On Air');
    });

    socket[0].on('reconnect', () => {
        window.location.reload();
    })
    socket[0].on('forceDisconnect', async () => {
        window.myApp.onExitClick(true); //SENDING A FAKE true
        // localStorage.setItem('isLogged', 'false');
        // await removeRealDB();
        console.log('forcedDisconnect!!!')
    })

    socket[0].on('updateUserData', (data) => {
        console.log('Received updateUserData !!!');
        let clearedEvents = userData.realEvents.filter(event => { return event.team !== data.teamId }); //ARRAY WITH EVENTS WITH THE REST OF TEAMS
        let clearedTeams = userData.realTeams.filter(team => { return team._id !== data.teamId }); //ARRAY WITH THE REST OF TEAMS
        //UPDATING userData's EVENTS:
        data.events.forEach(event => {
            clearedEvents.push(event); //ADDING TEAM'S UPDATED EVENTS
        });
        userData.realEvents = clearedEvents; //UPDATING EVENTS
        //UPDATING userData's TEAM:
        clearedTeams.push(data.team); //ADDING UPDATED TEAM
        userData.realTeams = clearedTeams; //UPDATING TEAMS
        createRealDB(userData.user, userData.realEvents, userData.realTeams, userData.realRecurrent, userData.realEarnings);
        window.myApp.updateApp();
    })

    socket[0].on('addEvent', async (data) => {
        userData.realEvents.push(data);
        await db.events.put(data);
        if (window.myEvents) {
            window.myEvents.updateApp(`${data.author} added ${data.type} on ${data.date} !`);
        }
        console.log("data: ", data)
    });

    socket[0].on('deleteTeam', data => {

        if (data.team.owner === data.user.email) {
            if (window.myApp) {
                //DELETING TEAM
                userData.realTeams.forEach((team, index) => {
                    if (team._id === data.team._id) {
                        userData.realTeams.splice(index, 1);
                    }
                })
                //DELETE TEAM'S EVENTS -- like aux 
                let filteredEvents = userData.realEvents.filter((event) => { return event.team !== data.team._id });
                userData.realEvents = filteredEvents;

                window.myApp.updateApp(`${data.user.name} erased ${data.team.teamName} and all it's events !`);
            }
        } else {
            if (window.myApp) {
                userData.realTeams.forEach(team => {
                    if (team._id === data.team._id) {
                        team.teamMembers.forEach((member, index) => {
                            if (member.email === data.user.email) {
                                team.teamMembers.splice(index, 1);
                            }
                        })
                    }
                })
                window.myApp.updateApp(`${data.user.name} left the ${data.team.teamName} team!`);
            }
        }

        console.log("data: ", data)
    });

    socket[0].on('editTeamName', data => {
        userData.realTeams.forEach(team => {
            if (team._id === data.teamId) {
                let oldTeamName = team.teamName;
                team.teamName = data.newName;
                window.myApp.updateApp(`${data.user} changed name of ${oldTeamName} with ${team.teamName}!`);
            }
        })
    })

    socket[0].on('removeEvent', async data => {
        userData.realEvents.forEach((event, index) => {
            if (data.event._id === event._id) {
                userData.realEvents.splice(index, 1);
            }
        })
        await db.events.delete(data.event._id);
        if (window.myEvents) {
            window.myEvents.updateApp(`${data.requester.name} removed ${data.event.type} from ${data.event.date} !`);
        }
    })

    socket[0].on('editEvent', data => {
        let somethingChanged = false;
        let oldType = [];
        userData.realEvents.forEach(event => {
            if (event._id === data._id) {
                oldType[0] = event.type;
                for (let prop in event) {
                    if (event[prop] !== data[prop]) {
                        somethingChanged = true;
                        event[prop] = data[prop];
                    }
                }
            }
        })
        if (somethingChanged && window.myEvents) {
            window.myEvents.updateApp();
        }
    })

    socket[0].on('addMember', async (data) => {
        let isMemberAlready = userData.realTeams.find(team => { return team._id === data.team._id });
        if (isMemberAlready) {
            if (data.requester.email !== userData.user.email) {
                userData.realTeams.forEach(team => {
                    if (team._id === data.team._id) {
                        team.teamMembers.push(data.member);
                    }
                })
                window.myApp.updateApp(`${data.requester.name} added ${data.member.name} to ${data.team.teamName}!`)
            }
        } else {
            console.log('User added !')
            userData.realTeams.push(data.team); //ADDING TEAM
            db.teams.put(data.team);
            // data.events.forEach(event => { //ADDING TEAM'S EVENTS
            //     userData.realEvents.push(event);
            // });
            for (let event of data.events) {//ADDING TEAM'S EVENTS
                userData.realEvents.push(event);
                await db.events.put(event);
            }
            db.events.bulkPut(data.events);
            window.myApp.updateApp(`${data.requester.name} added you to ${data.team.teamName}!`)
        }
    })

    socket[0].on('removeMember', async data => {
        let removedTeamName = [];
        if (data.memberToRemove.email === userData.user.email) { //IF USER IS THE REMOVED MEMBER
            userData.realTeams.forEach((team, index) => { //REMOVE TEAM FROM USER
                if (team._id === data.teamId) {
                    removedTeamName[0] = team.teamName;
                    userData.realTeams.splice(index, 1);
                }
            })
            await db.teams.delete(data.teamId);
            //DELETE TEAM'S EVENTS -- like aux 
            let filteredEvents = userData.realEvents.filter((event) => { return event.team !== data.teamId });
            userData.realEvents = filteredEvents;
            await db.events.where({ team: data.teamId }).delete();

            window.myApp.updateApp(`${data.requester} removed you from ${removedTeamName[0]}!`);
        } else {
            userData.realTeams.forEach(team => {
                team.teamMembers.forEach((member, index) => {
                    if (member.email === data.memberToRemove.email && team._id === data.teamId) {
                        removedTeamName[0] = team.teamName;
                        team.teamMembers.splice(index, 1);
                        window.myApp.updateApp(`${data.requester} removed ${data.memberToRemove.name} from ${removedTeamName[0]}!`);
                    }
                })
            })
        }
    })

    socket[0].on('disconnect', () => {
        window.myApp.setStatusMessage('disconnected');
    })

    socket[0].on('error', (err) => {
        console.log(err);
        emitData('onShowError', err);
    })
}

const emitData = (message, data) => {
    if (socket[0]) { //CHECK IF SOCKET CONNECTION EXISTS
        socket[0].emit(message, data);
    }
}

const disconnectSocket = () => {
    if (socket[0]) {
        socket[0].disconnect(true);
    }
}

export { connectSocket, emitData, disconnectSocket, socket };