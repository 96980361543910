import React, { Component } from 'react';
import Team from './Team';
import { removeTeam, editTeamName } from '../../queries/queries';
import { emitData } from '../../queries//socket';
import { db } from '../../queries/db';
import { addTempEditedTeam, addTempRemovedTeam } from '../../queries/temporary';


class Teams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newTeam: false,
            onOff: true
        }
    }

    onNewTeamClick = () => {
        this.setState({
            newTeam: !this.state.newTeam
        })
    }

    wrapperFunction = () => {
        this.onNewTeamClick();
        this.props.addNewTeam();
    }


    deleteTeam = async (event) => {
        const { userData, startWorking, showErrors } = this.props;
        startWorking(true, "Deleting team...");
        let teamId = event.target.parentNode.parentNode.parentNode.parentNode.getAttribute('uid');
        let teamOwner = event.target.parentNode.parentNode.parentNode.parentNode.getAttribute('owner');
        let anyError = [];
        let authToken = userData.user.authToken;
        let deletedTeam = await removeTeam(teamId, teamOwner, userData.user.email, authToken).catch(err => { anyError[0] = err; console.log(err) });
        if (deletedTeam) {
            //DELETING TEAM
            await db.teams.delete(deletedTeam.data.removeTeam.removedTeam._id);
            userData.realTeams.forEach((team, index) => {
                if (team._id === deletedTeam.data.removeTeam.removedTeam._id) {
                    userData.realTeams.splice(index, 1);
                    this.setState({ onOff: !this.state.onOff });
                    // this.props.onLoginSubmit();
                }
            })
            //DELETE TEAM'S EVENTS -- like aux 
            let filteredEvents = userData.realEvents.filter((event) => { return event.team !== deletedTeam.data.removeTeam.removedTeam._id });
            userData.realEvents = filteredEvents;
            emitData('deleteTeam', {
                team: deletedTeam.data.removeTeam.removedTeam,
                // events: deletedTeam.data.removeTeam.removedEvents,
                user: {
                    name: userData.user.name,
                    email: userData.user.email
                }
            });
            db.transaction("rw", db.events, async () => {
                await db.events.where("team").equals(teamId).delete();
            })
            startWorking(false);
        } else {
            let teamToRemove = userData.realTeams.find(team => { return team._id === teamId });
            addTempRemovedTeam(teamToRemove);
            startWorking(false);
            console.log(anyError);
            if (anyError[0].networkError) {
                showErrors(true, ["Network error! Can't delete this team. Please try again later!"]);
            } else {
                if (anyError[0].message === 'GraphQL error: jwt expired') {
                    showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
                    window.myApp.onExitClick();
                } else {
                    showErrors(true, ["Error! Can't delete this team. Please try again later!"])
                }
            }
        }
    }

    editTeamName = async (event) => {
        const { userData, startWorking, showErrors } = this.props;
        let teamId = event.target.parentNode.parentNode.parentNode.parentNode.getAttribute('uid');
        let input = document.getElementById('newteamname');
        let authToken = userData.user.authToken;
        if (input.value.length > 0) {
            startWorking(true, "Changing team name...");
            let anyError = [];
            let newTeamName = await editTeamName(userData.user.email, teamId, input.value.trim(), authToken).catch(err => { anyError[0] = err; console.log(err) })
            if (newTeamName) {
                //CHANGE TEAM'S NAME
                userData.realTeams.forEach((team) => {
                    if (team._id === teamId) {
                        team.teamName = newTeamName.data.editTeamName;
                    }
                });
                await db.teams.update(teamId, { teamName: newTeamName.data.editTeamName });
                emitData('editTeamName', {
                    teamId: teamId,
                    newName: newTeamName.data.editTeamName,
                    user: userData.user.name
                })
                startWorking(false);
                this.setState({ onOff: !this.state.onOff });
            } else {
                //ADD EDITED TEAM TO editedTeams TABLE:
                let editedTeam = userData.realTeams.find(team => { return team._id === teamId });
                editedTeam.teamName = input.value.trim();
                addTempEditedTeam(editedTeam);
                //....................................
                startWorking(false);
                console.log(anyError);
                if (anyError[0].networkError) {
                    showErrors(true, ["Network error! Can't edit this team name. Please try again later!"]);
                } else {
                    if (anyError[0].message === 'GraphQL error: jwt expired') {
                        showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
                        window.myApp.onExitClick();
                    } else {
                        showErrors(true, ["Error! Can't edit this team name. Please try again later!"])
                    }
                }
            }
        }
    }

    render() {
        const { newTeam } = this.state;
        const { userData, showErrors } = this.props;
        let visible = 'ba w-100 pa3 ma2';
        let invisible = 'display-none';

        return (
            <div className=''>
                <div onClick={this.onNewTeamClick} className='ma1 pa2 w-100 shadow-1 bg-dark-gray light-blue br3 grow tc pointer'>+ NEW TEAM</div>
                <div className={newTeam ? visible : invisible}>
                    <div>
                        <input type='text' id='newteam' className='ma1' placeholder='Team Name'></input><button onClick={this.wrapperFunction}>CREATE</button>
                    </div>

                </div>
                {userData.realTeams.map(team =>
                    <Team showErrors={showErrors} startWorking={this.props.startWorking} userData={userData} users={this.props.users} team={team} inputId={team._id + Math.random()} key={team._id} addNewMember={this.addNewMember} deleteTeam={this.deleteTeam} editTeamName={this.editTeamName} addingStatus={this.state.addingStatus} />
                )}
            </div>

        )
    }
}
export default Teams;