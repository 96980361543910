import React, { Component } from 'react';

class DemoTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            demo: this.props.demoState
        }
    }
    wrapperDemo = () => {
        this.props.demo();
        this.setState({ demo: !this.state.demo });
    }
    render() {
        let lightTab = 'ba br3 w-100 tc pa0 ma1 f4 dim pointer white shadow-3 h4 dt noselect';
        const { demo } = this.state;
        return (
            <div className='w-100' onClick={this.wrapperDemo}>
                <div className=''>
                    <div className={demo ? lightTab.concat(' bg-light-red') : lightTab.concat(' bg-blue')} ><div className='fw9 ma3 dtc v-mid f4-ns f5' >{this.state.demo ? 'Real Events' : 'Demo Events'}</div></div>
                </div>

            </div>
        )
    }
}
export default DemoTab;