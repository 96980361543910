import React, { Component } from 'react';
import { editUserName, editUserRole, editPassword, removeUser } from '../../queries/queries';
import editIcon from '../../pictures/edit-2.svg';
import { db } from '../../queries/db';
import { addTempEditedUser, addTempEditedTeam } from '../../queries/temporary';

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reRender: false,
            changePassword: false,
            editName: false,
            editRole: false,
            deleteAccount: false
        }
    }

    onChangePasswordClick = () => {
        this.setState({ changePassword: !this.state.changePassword })
    }

    onEditNameClick = () => {
        this.setState({ editName: !this.state.editName })
    }
    onEditRoleClick = () => {
        this.setState({ editRole: !this.state.editRole })
    }

    saveNewName = async () => {
        const { userData, startWorking, showErrors } = this.props;
        let nameInput = document.getElementById('changeName');
        if (nameInput.value.length > 1) {
            startWorking(true, 'Changing name...')
            let anyError = [];
            let newName = await editUserName(userData.user.email, nameInput.value.trim(), userData.user.authToken).catch(err => { anyError[0] = err });
            if (newName) {
                let teamsToUpdate = [];
                userData.user.name = newName.data.editUserName;
                userData.realTeams.forEach(team => { //searching for user in team's membership's
                    team.teamMembers.forEach(member => {
                        if (member.email === userData.user.email) {
                            member.name = newName.data.editUserName //Save received team member name
                            teamsToUpdate.push(team);
                        }
                    })
                })
                await db.user.update(userData.user.id, { name: newName.data.editUserName });
                await db.teams.bulkPut(teamsToUpdate);
                this.props.onUserNameChange();
                this.setState({ editName: !this.state.editName })
                startWorking(false);
            } else {
                //ADD EDITED USER TO editedUser TABLE:
                let userToEdit = userData.user;
                userToEdit.name = nameInput.value.trim();
                addTempEditedUser(userToEdit);
                //ADD EDITED TEAMS TO editedTeams TABLE:
                let teamsToEdit = userData.realTeams;
                for (let team of teamsToEdit) {
                    for (let member of team.teamMembers) {
                        if (member.email === userData.user.email) {
                            member.name = nameInput.value.trim();
                        }
                    }
                }
                for (let team of teamsToEdit) {
                    addTempEditedTeam(team);
                }
                //.....................................
                startWorking(false);
                console.log(anyError);
                if (anyError[0].networkError) {
                    showErrors(true, ["Network error! Can't edit this name. Please try again later!"]);
                } else {
                    if (anyError[0].message === 'GraphQL error: jwt expired') {
                        showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
                        window.myApp.onExitClick();
                    } else {
                        showErrors(true, ["Error! Can't edit this name. Please try again later!"])
                    }
                }
            }
        } else {
            nameInput.setAttribute('style', 'border-color: red');
        }
    }
    saveNewRole = async () => {
        const { userData, startWorking, showErrors } = this.props;
        let nameInput = document.getElementById('changeRole');
        if (nameInput.value.length > 1) {
            startWorking(true, 'Changing role...')
            let anyError = [];
            let newRole = await editUserRole(userData.user.email, nameInput.value.trim(), userData.user.authToken).catch(err => { anyError[0] = err });
            if (newRole) {
                let teamsToUpdate = [];
                userData.user.role = newRole.data.editUserRole; //Save received role
                userData.realTeams.forEach(team => { //searching for user in team's membership's
                    team.teamMembers.forEach(member => {
                        if (member.email === userData.user.email) {
                            member.role = newRole.data.editUserRole //Save received team member role
                            teamsToUpdate.push(team);
                        }
                    })
                })
                await db.user.update(userData.user.id, { role: newRole.data.editUserRole });
                await db.teams.bulkPut(teamsToUpdate);
                this.setState({ editRole: !this.state.editRole });
                startWorking(false);
            } else {
                //ADD EDITED USER TO editedUser TABLE:
                let userToEdit = userData.user;
                userToEdit.role = nameInput.value.trim()
                addTempEditedUser(userToEdit);
                //ADD EDITED TEAMS TO editedTeams TABLE:
                let teamsToEdit = userData.realTeams;
                for (let team of teamsToEdit) {
                    for (let member of team.teamMembers) {
                        if (member.email === userData.user.email) {
                            member.role = nameInput.value.trim();
                        }
                    }
                }
                for (let team of teamsToEdit) {
                    addTempEditedTeam(team);
                }
                //.....................................
                startWorking(false);
                console.log(anyError);
                if (anyError[0].networkError) {
                    showErrors(true, ["Network error! Can't edit this role. Please try again later!"]);
                } else {
                    if (anyError[0].message === 'GraphQL error: jwt expired') {
                        showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
                        window.myApp.onExitClick();
                    } else {
                        showErrors(true, ["Error! Can't edit this role. Please try again later!"])
                    }
                }
            }
        } else {
            nameInput.setAttribute('style', 'border-color: red');
        }
    }

    onDeleteAccountClick = () => {
        this.setState({ deleteAccount: !this.state.deleteAccount })
    }

    onDeleteAccountSubmit = async () => {
        const { userData, startWorking, showErrors } = this.props;
        let password = document.getElementById('passwordForDelete');
        if (password.value.trim()) {
            this.props.startWorking(true, 'Deleting account...');
            let anyError = [];
            let removedUser = await removeUser(userData.user.email, password.value.trim()).catch(err => { anyError[0] = err; console.log(err) });
            if (removedUser) {
                console.log(`Account ${removedUser.data.removeUser.email} removed !`);
                password.setAttribute('style', 'border-color: inherit');
                password.value = '';
                await this.props.exit();
                this.props.startWorking(false);
            } else {
                startWorking(false);
                console.log(anyError);
                if (anyError[0].networkError) {
                    showErrors(true, ["Network error! Can't delete this account. Please try again later!"]);
                } else {
                    showErrors(true, ["Error! Can't delete this account. Please try again later!"])
                }
            }
        } else {
            password.setAttribute('style', 'border-color: red');
        }
    }

    onConfirmChangePassword = async () => {
        const { userData, startWorking, showErrors } = this.props;
        let oldPassword = document.getElementById('oldPassword');
        let newPassword1 = document.getElementById('newPassword1');
        let newPassword2 = document.getElementById('newPassword2');

        if (oldPassword.value && newPassword1.value && newPassword2.value) {
            [oldPassword, newPassword1, newPassword2].forEach(field => field.setAttribute("style", "border-color: inherit"));
            if (newPassword1.value.trim() === newPassword2.value.trim()) {
                [newPassword1, newPassword2].forEach(field => field.setAttribute("style", "border-color: inherit"));
                this.props.startWorking(true, 'Changing password...');
                let anyError = [];
                let newPass = await editPassword(userData.user.email, oldPassword.value.trim(), newPassword2.value.trim()).catch(err => { anyError[0] = err; console.log(err) });
                if (newPass) {
                    console.log('Password Changed : ', newPass.data.editPassword);
                    this.setState({ changePassword: !this.state.changePassword });
                    this.props.startWorking(false);
                    // alert('Your password has been changed !')
                } else {
                    startWorking(false);
                    console.log(anyError);
                    if (anyError[0].networkError) {
                        showErrors(true, ["Network error! Can't change password right now. Please try again later!"]);
                    } else {
                        showErrors(true, ["Error! Can't change password right now. Please try again later!"])
                    }
                }
            } else {
                newPassword1.setAttribute('style', 'border-color: red');
                newPassword2.setAttribute('style', 'border-color: red');
            }
        } else {
            [oldPassword, newPassword1, newPassword2].forEach(field => {
                if (!field.value) {
                    console.log(field)
                    field.setAttribute('style', 'border-color: red');
                }
            })
        }
    }

    render() {
        let lightTab = 'ba br3 w-50 tc pa0 ma1 f4 dim pointer bg-light-red white shadow-3 h3 dt noselect center';
        let inputStyle = 'ma2 center w-100 ';
        const { userData } = this.props;
        const { changePassword, editName, editRole, deleteAccount } = this.state;
        return (
            <div className='w-100 center w-70-ns' >
                <h2 className='bg-black white br2 o-80 pa3 '>{editName ? (<input placeholder={userData.user.name} className='f4' id='changeName' type='text'></input>) : userData.user.name} {editName ? (<><button onClick={this.saveNewName} className='f4'>Change</button> <button onClick={() => { this.setState({ editName: !editName }) }} className='f4'>Cancel</button></>) : (<img src={editIcon} onClick={this.onEditNameClick} className='noselect pointer dim ml-2 bg-white' alt='' title="edit name" />)} </h2>
                <h2 className='bg-black white br2 o-80 pa3'>{editRole ? (<input placeholder={userData.user.role} className='f4' id='changeRole' type='role'></input>) : userData.user.role} {editRole ? (<><button onClick={this.saveNewRole} className='f4'>Change</button> <button onClick={() => { this.setState({ editRole: !editRole }) }} className='f4'>Cancel</button></>) : (<img src={editIcon} onClick={this.onEditRoleClick} className='noselect pointer dim ml-2 bg-white' alt='' title="edit role" />)} </h2>
                <h2 className='bg-black white br2 o-80 pa3'>{userData.user.email}</h2>
                <div className={lightTab} onClick={this.onChangePasswordClick}><h4>Change Password </h4></div>
                {changePassword ? (
                    <div className='pa3 bg-dark-gray w-100 dt tc center delete-options'>
                        <div className='center w-100 bg-black pa4 br3 deleteAccount'>
                            <input className={inputStyle} type='password' placeholder='current password' id='oldPassword'></input>
                            <input className={inputStyle} type='password' placeholder='new password' id='newPassword1'></input>
                            <input className={inputStyle} type='password' placeholder='new password' id='newPassword2'></input>
                            <button className='ma2' onClick={this.onConfirmChangePassword}>Change</button> <button className='ma2' onClick={this.onChangePasswordClick}>Cancel</button>
                        </div>
                    </div>
                ) : (null)}
                <div className={lightTab} onClick={this.onDeleteAccountClick}><h4>Delete Account </h4></div>
                {deleteAccount ? (
                    userData.realTeams.length ? (
                        <div className='pa3 bg-dark-gray w-100 dt tc center delete-options'>
                            <div className='w-100 red bg-black tc pa3 deleteAccount'>
                                <h2>You still got teams !</h2>
                                <h2>Delete all teams before you permanently delete your acount and data. </h2>
                                <div className='w-50 ba center pa2 bg-dark-gray b--light-blue dim pointer white noselect' onClick={() => { this.setState({ deleteAccount: !deleteAccount }) }}>Cancel</div>
                            </div>
                        </div>
                    ) : (<div className='pa3 bg-dark-gray w-100 dt tc center delete-options'>
                        <div className='w-100 red bg-black tc pa3 deleteAccount'>
                            <input className='ma2 f4 br2' type='password' placeholder='password' id='passwordForDelete'></input>
                            <h1>WARNING !!!</h1>
                            <h2>You will permanently delete your account and your data !</h2>
                            <div className='w-100 tc flex'>
                                <div className='w-50 ba center pa2 bg-dark-gray b--light-blue dim pointer white noselect' onClick={() => { this.setState({ deleteAccount: !deleteAccount }) }}>Cancel</div>
                                <div className='w-50 ba center pa2 bg-dark-gray b--light-blue dim pointer red noselect' onClick={this.onDeleteAccountSubmit}>Delete</div>
                            </div>
                        </div>
                    </div>
                        )
                ) : (null)}
            </div>
        )
    }
}
export default UserProfile;