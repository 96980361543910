import React, { Component } from 'react';
import DeleteTeam from './DeleteTeam';
import EditTeamName from './EditTeamName';
import AddMember from './AddMember';
import RemoveMember from './RemoveMember';
import userPlusIcon from '../../pictures/user-plus.svg';
import userMinusIcon from '../../pictures/user-minus.svg';
import editIcon from '../../pictures/edit.svg';
import trashIcon from '../../pictures/trash-2.svg';
import settingsIcon from '../../pictures/settings.svg';

class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionsClicked: false,
            options: 'nothing',
            addingStatus: 'noadd',
            reRender: false
        }
    }

    onAddMemberClick = () => {
        this.state.options !== 'addmember' ? this.setState({ options: 'addmember' }) : this.setState({ options: 'nothing', addingStatus: 'noadd' })
    }
    onRemoveMemberClick = () => {
        this.state.options !== 'removemember' ? this.setState({ options: 'removemember' }) : this.setState({ options: 'nothing' });
    }
    onEditNameClick = () => {
        this.state.options !== 'editname' ? this.setState({ options: 'editname' }) : this.setState({ options: 'nothing' })
    }
    onDeleteTeamClick = () => {
        this.state.options !== 'deleteteam' ? this.setState({ options: 'deleteteam' }) : this.setState({ options: 'nothing' })
    }
    onOptionsClick = () => {
        this.setState({ optionsClicked: !this.state.optionsClicked })
        if (!this.state.optionsClicked) { this.setState({ options: 'nothing' }) }
    }
    reRender = () => { this.setState({ reRender: !this.state.reRender }) }


    switchState = (type, team) => {
        switch (type) {
            case 'addmember': return <AddMember showErrors={this.props.showErrors} startWorking={this.props.startWorking} users={this.props.users} inputId={this.props.inputId} userData={this.props.userData} reRender={this.reRender} addNewMember={this.props.addNewMember} addingStatus={this.state.addingStatus} />;
            case 'removemember': return <RemoveMember showErrors={this.props.showErrors} startWorking={this.props.startWorking} teamMembers={team.teamMembers} userData={this.props.userData} reRender={this.reRender} owner={team.owner} />;
            case 'editname': return <EditTeamName startWorking={this.props.startWorking} editTeamName={this.wrapperEditTeam} team={team} />;
            case 'deleteteam': return <DeleteTeam startWorking={this.props.startWorking} answerNo={this.onDeleteTeamClick} answerYes={this.props.deleteTeam} team={team} userData={this.props.userData} />;
            default: return <p>Some Options</p>
        }
    }

    wrapperEditTeam = (e) => {
        this.props.editTeamName(e);
        this.onEditNameClick();
    }

    wrapperDeleteTeam = (e) => {
        this.props.deleteTeam(e);
        this.onDeleteTeamClick();
    }


    render() {
        const { team } = this.props;
        const { options, optionsClicked } = this.state;

        return (
            <div>
                <div key={team._id} uname={team.teamName} uid={team._id} id={team._id} owner={team.owner} className='ba br3 pa2 ma2 noselect b--near-white shadow-3 teamBackground' >
                    <h1 className='ma1 tc'>{team.teamName}</h1>
                    <h5 className='tc ma1'>{team.teamMembers.map(member => <span key={member.email}> | {member.name} - {member.role} </span>)} |</h5>
                    <div className='tc dim pointer ma' onClick={this.onOptionsClick}><img src={settingsIcon} height='32px' title='OPTIONS' alt=''></img></div>
                    <div className={optionsClicked ? null : 'display-none'}>
                        <div className={options === 'nothing' ? 'display-none' : 'b--light-silver tc'}>
                            {this.switchState(options, team)}
                        </div>
                        <div className='flex'>
                            <span className="ma2 pa1 grow pointer noselect pv1 w-25 tc ba br3 b--gray" style={{ fontSize: 22 + 'px' }} onClick={this.onAddMemberClick} title='Add New Team Member'> <img src={userPlusIcon} alt=''></img></span>
                            <span className="ma2 grow pointer noselect pv1 w-25 tc ba br3 b--gray" style={{ fontSize: 22 + 'px' }} onClick={this.onRemoveMemberClick} title='Remove Team Member'><img src={userMinusIcon} alt=''></img></span>
                            <span className='ma2 grow pointer noselect pv1 w-25 tc ba br3 b--gray' style={{ fontSize: 22 + 'px' }} onClick={this.onEditNameClick} title="Edit Team's Name"><img src={editIcon} alt=''></img></span>
                            <span className='ma2 grow pointer pv1 noselect w-25 tc ba br3 b--gray' style={{ fontSize: 22 + 'px', color: 'red' }} onClick={this.onDeleteTeamClick} title='Delete Team'><img src={trashIcon} alt=''></img></span>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Team;