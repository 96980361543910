import React from 'react';
import { emitData } from '../queries/socket';

const ShowError = ({ onOk, arr }) => {
    emitData('onShowError', arr)
    return (
        <div className='pa3 bg-dark-gray working-options w-100 dt tc center'>
            <div className='pa5 bg-near-white details w-90 center tc  dtc v-mid h-100'>
                <div className='light-blue f2 fw8'>EventsDB</div>
                <div className='fw8 pa2'>Ooops, problems... !</div>
                {arr ? (
                    arr.map((err, i) => {
                        return <div key={i}>{i + 1}: {err}</div>
                    })
                ) : (null)}
                <div className="b pa2 mt3 ba b--black bg-transparent grow pointer f6 dib ma0 center noselect" onClick={onOk}>OK</div>
            </div>
        </div>
    )
}


export default ShowError;