import React, { Component } from 'react';
import CreateCalendarTable from './CreateCalendarTable';

class CalendarDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    convertDate = (date) => {
        let d = new Date(date);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();

        return (`${day} ${months[month]} ${year}`)
    }

    switchDisplay = (arr, dateClicked) => {
        switch (arr.length) {
            case 0: return <div >
                <p>No Event on this date</p>
                <div className='ba tc pa2 mt3 w-50 w-third-ns f4 f5-ns pointer noselect shadow-1 fw9 grow center' onClick={this.props.close}>CLOSE</div>
                <div className='ba tc pa2 mt3 w-50 w-third-ns f4 f5-ns dim pointer noselect shadow-1 fw9 grow center' onClick={this.props.newEvent}>+ NEW EVENT</div>

            </div>
            default: return <div>
                <h3>Events on {this.convertDate(arr[0].date)}:</h3>
                <CreateCalendarTable events={arr} options={this.props.options} dateClicked={dateClicked} />
                <div className='ba tc pa2 mt3 w-50 w-third-ns f4 f5-ns pointer noselect shadow-1 fw9 grow center' onClick={this.props.close}>CLOSE</div>
                <div className='ba tc pa2 mt3 w-50 w-third-ns f4 f5-ns dim pointer noselect shadow-1 fw9 grow center' onClick={this.props.newEvent}>+ NEW EVENT</div>
            </div>
        }
    }

    render() {
        const { events, dateClicked, closeDiv, del, saveEvent } = this.props;
        return (
            <div className='pa3 bg-dark-gray bg-options w-100 dt tc center'>
                <div className=' bg-near-white details w-90 center tc  dtc v-mid h-100'>
                    {/* {this.switchState(type)} */}
                    {this.switchDisplay(events, dateClicked, closeDiv, del, saveEvent)}
                </div>
            </div>
        )
    }
}
export default CalendarDetails;