import React from 'react';
import toggleRight from '../../pictures/toggle-right.svg';
import toggleLeft from '../../pictures/toggle-left.svg';

function convertCalendarDate(date) {
    let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    if (month + 1 < 10 && day < 10) {
        return (`${year}-0${month + 1}-0${day}`)
    }
    if (month + 1 < 10 && day >= 10) {
        return (`${year}-0${month + 1}-${day}`)
    }
    if (month + 1 >= 10 && day < 10) {
        return (`${year}-${month + 1}-0${day}`)
    }
    if (month + 1 >= 10 && day >= 10) {
        return (`${year}-${month + 1}-${day}`)
    }
}

const NewEntry = ({ closeDiv, addNewEvent, currentDate, teams, stateTeam, events, isRecurrent, recurrentType, onTypeButtonClick, onRecurrentEventSelect, userData }) => {

    let newEntryStyle = '';
    let divStyle = 'flex flex-wrap w-50';
    let divStyleDescription = 'flex flex-wrap w-100 w-60-ns center ';
    let inputStyle = 'f3 ma1 ba b--near-white  w-100 bg-dark-gray light-blue hover-bg-black';

    const switchState = function (type) { //CREATING A FUNCTION TO RENDER A <select></select/> WITH DEFAULT VALUE OF "allteams" OR OF THE SELECTED TEAM
        let currentTeam;
        if (type !== "allteams") { //GETTING THE SELECTED TEAM
            currentTeam = teams.find(team => {
                return team._id === type;
            });
        }

        switch (type) {
            case 'allteams': return (//RENDER A "allteams" DEFAULT VALUE SELECT
                <select id='selectNewEntry' name="teams" defaultValue="allteams" className='tc pa1 ma1 w-100 f5 dim pointer noselect shadow-1 fw9 center bg-dark-gray white' title={stateTeam !== 'allteams' ? "Team already selected !" : 'Select team'}>
                    <option></option>
                    {teams.map(team => {
                        return <option value={team.teamName} key={team._id} id={team._id} owner={team.owner}>{team.teamName} </option>
                    })}
                </select>
            );
            default: return (//RENDER A CURRENT TEAM VALUE SELECT
                <select id='selectNewEntry' name="teams" className='tc pa1 ma1 w-100 f5 dim pointer noselect shadow-1 fw9 center bg-dark-gray white' title={stateTeam !== 'allteams' ? "Team already selected !" : 'Select team'}>
                    <option value={currentTeam.teamName} key={currentTeam._id} id={currentTeam._id} owner={currentTeam.owner}>{currentTeam.teamName} </option>
                    {
                        teams.map(team => {
                            if (team._id !== currentTeam._id) {
                                return <option value={team.teamName} key={team._id} id={team._id} owner={team.owner}>{team.teamName} </option>
                            }
                            return null;
                        })
                    }
                </select>
            );
        }
    }

    return (
        <div className={newEntryStyle}>
            <div className='pa3 bg-black bg-options w-100 dt'>
                <div className='pl2 pr2 bg-near-white details w-90 center tc  dtc v-mid ' >
                    <div className='center h-50'>
                        {userData.realRecurrent.length > 0 ? (
                                <div className='dt w-100 w-60-ns center'>
                                {recurrentType ? <div className='dt'><img src={toggleRight} onClick={onTypeButtonClick} alt='' className='mr1 v-mid'></img><span>new</span><span className='fw8'>/recurrent</span></div> : <div className='dt'><img src={toggleLeft} onClick={onTypeButtonClick} alt='' className='mr1 v-mid'></img><span className='fw8'>new/</span><span>recurrent</span></div>}
                            </div>
                        ) : null}

                        <form className='flex flex-wrap' >
                            <div className='flex flex-wrap w-100 w-60-ns center'>
                                <div className={divStyle}>
                                    {recurrentType ?
                                        <select className={'f3 ma1 ba b--near-white  w-100 bg-dark-gray light-blue '} required id='type' onChange={onRecurrentEventSelect}>
                                            {userData.realRecurrent.length >= 1 ? <option value='nothing'></option> : null}
                                            {userData.realRecurrent.map(event => {
                                                return <option value={event.type} key={event._id} >{event.type}</option>
                                            })}
                                        </select>
                                        : <>
                                            <input className={'f3 ma1 ba b--near-white  w-100 bg-dark-gray light-blue hover-bg-black'} required id='type' placeholder='TYPE' list='types'></input>
                                            <datalist id='types'>
                                                {Array.from(new Set(events.map(event => { return event.type }))).map(event => {
                                                    return <option key={event}>{event}</option>
                                                })}
                                            </datalist>
                                        </>}
                                </div>
                                <div className={divStyle}>
                                    <input className={inputStyle} type='date' required id='date' defaultValue={convertCalendarDate(currentDate)}></input>
                                </div>
                            </div>
                            <div className='flex flex-wrap w-100 w-60-ns center'>
                                <div className={divStyle}>
                                    <input className={inputStyle} type='text' id='city' placeholder='CITY' list='cities'></input>
                                    <datalist id='cities'>
                                        {Array.from(new Set(events.map(event => { return event.city }))).sort().map(event => {
                                            return <option key={event}>{event}</option>
                                        })}
                                    </datalist>
                                </div>
                                <div className={divStyle}>
                                    <input className={inputStyle} type='text' id='location' placeholder='LOCATION' list='locations'></input>
                                    <datalist id='locations'>
                                        {Array.from(new Set(events.map(event => { return event.location }))).sort().map(event => {
                                            return <option key={event}>{event}</option>
                                        })}
                                    </datalist>
                                </div>
                            </div>

                            <div className='flex flex-wrap w-100 w-60-ns center'>
                                <div className={divStyle}>
                                    <input className={inputStyle} type='number' id='price' placeholder='PRICE' list='prices'></input>
                                    <datalist id='prices'>
                                        {Array.from(new Set(events.map(event => { return event.price }))).sort((a, b) => { return b - a }).map(event => {
                                            return <option key={event}>{event}</option>
                                        })}
                                    </datalist>
                                </div>
                                <div className={divStyle}>
                                    <input className={inputStyle} type='number' id='earnest' placeholder='EARNEST' list='earnests'></input>
                                    <datalist id='earnests'>
                                        {Array.from(new Set(events.map(event => { return event.earnest }))).sort((a, b) => { return b - a }).map(event => {
                                            return <option key={event}>{event}</option>
                                        })}
                                    </datalist>
                                </div>
                            </div>

                            <div className={divStyleDescription}>
                                <textarea className={inputStyle} id='description' placeholder='DESCRIPTION'></textarea>
                            </div>
                            <div className='flex flex-wrap w-60-ns w-100 center dt'>
                                <p className='center f5 w-50 v-mid'>TEAM:</p> 
                                <div className='w-50 dt'>{switchState(stateTeam)/*RENDERING A <select></select> */}</div>
                            </div>
                            {recurrentType ? null :
                                <div className={divStyleDescription}>
                                    <input type='checkbox' name='recurrent' value='no' className='ma1' id='reccurent' onClick={isRecurrent} />
                                    <div>Save As  RECURRENT EVENT</div>
                                </div>}

                            <div className='flex w-100 center ma1 h3'>
                                <div className='ba br3 w-20-ns w-30-m w-40 tc pa1 ma3 f4 dim pointer center noselect shadow-1 fw9' onClick={addNewEvent}>Add</div>
                                <div className='ba br3 w-20-ns w-30-m w-40 tc pa1 ma3 f4 dim pointer center noselect shadow-4 fw9' onClick={closeDiv}>Close</div>
                            </div>
                        </form>
                    </div>
                    <div className='h-50'>   </div>
                </div>
            </div>
        </div >
    )
}
export default NewEntry;