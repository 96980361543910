import React, { Component } from 'react';
import { addTeamMember } from '../../queries/queries';
import { emitData } from '../../queries/socket';
// import { addTempEditedTeam } from '../../queries/temporary';

class AddMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addingStatus: this.props.addingStatus,
        }
    }

    switchAddingStatus = (status) => {
        switch (status) {
            case 'noadd': return <p className='light-blue ma0 f6'>Add to your team: </p>
            case 'joined': return <p className='light-blue ma0 f6'>Oops, user already joined!</p>
            case 'notregistered': return <p className='red ma0 f6'>Sorry, incorrect email or user not registered! <span className='light-blue pointer dim'>Tell your mate about this cool app!</span></p>
            case 'done': return <p className='light-blue ma0 f6'>Cool! User added!</p>
            default: return null
        }
    }

    addNewMember = async (e) => {
        const { userData, startWorking, showErrors } = this.props;
        let newMember = document.getElementById(this.props.inputId);         //GETTING INPUT
        let teamId = e.target.parentNode.parentNode.parentNode.parentNode.getAttribute('uid'); //GETTING ID OF CURRENT TEAM
        let owner = e.target.parentNode.parentNode.parentNode.parentNode.getAttribute('owner'); //GETTING ID OF CURRENT TEAM
        let anyError = []; //STORING ERROR BUT DOING NOTHING
        let authToken = userData.user.authToken;
        startWorking(true, 'Adding member...');
        let foundUser = await addTeamMember(newMember.value.toLowerCase().trim(), teamId, owner, authToken).catch(err => { anyError[0] = err; }); //GETTING OBJECT OF USER IF FOUND IN DB
        //CHECKING DB IF USER IS ALREADY IN THE CURRENT TEAM - TRUE OR FALSE:
        let userAlreadyAdded = userData.realTeams.filter(team => team._id === teamId)[0].teamMembers.some(member => { return member.email === newMember.value.toLowerCase().trim() });
        if (!userAlreadyAdded) { //IF USER IS NOT ALREADY ADDED
            if (foundUser) { //IF USER IS FOUND
                userData.realTeams.forEach(team => {
                    if (team._id === teamId) {
                        team.teamMembers.push(foundUser.data.addTeamMember);
                        let events = userData.realEvents.filter(event => {
                            return event.team === teamId;
                        }); console.log(events);
                        emitData('addMember', {
                            member: foundUser.data.addTeamMember,
                            requester: userData.user,
                            team: team,
                            events: events
                        })
                        this.setState({
                            addingStatus: 'done',
                        });
                        this.props.reRender();
                        newMember.value = '';
                        newMember.setAttribute('style', 'border-color: inherit');
                    }
                })
                this.props.startWorking(false);
            } else {
                startWorking(false);
                if (anyError[0].networkError) {
                    showErrors(true, ["Network Error! Can't add member right now. Please try again later!"])
                } else {
                    this.setState({
                        addingStatus: 'notregistered'
                    });
                    newMember.setAttribute('style', 'border-color: red');
                }
            }
        } else {
            startWorking(false);
            this.setState({
                addingStatus: 'joined'
            })
        }

    }
    onEnterKey = (e) => {
        if (e.keyCode === 13) this.addNewMember(e);
    }

    render() {
        return (
            <div className='ma1'>
                {this.switchAddingStatus(this.state.addingStatus)}
                <input className='ma2 pa2' id={this.props.inputId} onKeyDown={this.onEnterKey} placeholder='new member email' type='email' /> <button onClick={this.addNewMember}>Add</button>
            </div>
        )
    }
}
export default AddMember;