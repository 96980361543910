import React, { Component } from 'react';

class YearSelector extends Component {

    render() {
        const { years, onYearClick } = this.props;
        return (
            <select id='yearSelect' name="years" defaultValue={(new Date().getFullYear())} className='tc dt pa1 ma1 f6 dim pointer noselect shadow-1 fw9 bg-white w-100' onChange={onYearClick}>
                {years.map(year =>
                    <option value={year} key={year} >{year} </option>
                )}
            </select>
        )
    }
}
export default YearSelector;