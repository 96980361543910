import React from 'react';

const Working = ({ message }) => {
    return (
        <div className='pa3 bg-dark-gray working-options w-100 dt tc center'>
            <div className='pa5 bg-near-white details w-90 center tc  dtc v-mid h-100'>
                <div className='light-blue f2 fw8'>EventsDB</div>
                <div className='fw8'>{message}</div>
                <div className="spin"></div>
            </div>
        </div>
    )
}

export default Working;