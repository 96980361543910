import React from 'react';

const DeleteTeam = ({ answerNo, answerYes, team, userData }) => {
    return (
        <div className='bg-dark-gray white br3 pa1'>
            <h4 className='mv0'>Are you sure ?</h4>
            {userData.user.email === team.owner ? (
                <h3 className='mv0'>You are about to <span className='red'>PERMANENTLY DELETE {team.teamName.toUpperCase()}</span> and <span className='red'>ALL ITS EVENTS</span>  !</h3>
            ) : (
                    <h3 className='mv0'>You are about to <span className='red'>QUIT from {team.teamName.toUpperCase()}</span> and <span className='red'>REMOVE ALL ITS EVENTS</span>  !</h3>
                )}
            <button className='pa2 ma3' onClick={answerNo}>NO</button><button className='pa2 ma3' onClick={answerYes}>yes</button>
        </div>
    )
}
export default DeleteTeam;