import idGenerator from 'react-id-generator';

const Users = [
    {
        userID: idGenerator(),
        name: 'John',
        email: 'john@gmail.com',
        role: 'Trumpet'
    },
    {
        userID: idGenerator(),
        name: 'Mark',
        email: 'mark@gmail.com',
        role: 'Percusion'
    },
    {
        userID: idGenerator(),
        name: 'Tim',
        email: 'tim@gmail.com',
        role: 'Bass'
    },
    {
        userID: idGenerator(),
        name: 'Fergie',
        email: 'fergie@gmail.com',
        role: 'Vocal'
    },
    {
        userID: idGenerator(),
        name: 'Sami',
        email: 'sami@gmail.com',
        role: 'Keyboard Player'
    },
    {
        userID: idGenerator(),
        name: 'Ale',
        email: 'ale@gmail.com',
        role: 'Boss'
    }
];

export default Users;