import React, { Component } from 'react';
import { removeTeamMember } from '../../queries/queries';
import { emitData } from '../../queries/socket';
// import { addTempEditedTeam } from '../../queries/temporary';

class MemberToRemove extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remove: false
        }
    }

    removeUser = async (e) => {
        const { userData, startWorking, showErrors } = this.props;
        this.props.startWorking(true, 'Removing user...');
        let teamId = e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute('uid');
        let memberToRemove = e.target.parentNode.parentNode.getAttribute('email');
        let owner = this.props.owner;
        let anyError = [];
        let authToken = userData.user.authToken;
        let removedMember = await removeTeamMember(userData.user.email, memberToRemove, teamId, owner, authToken).catch(err => { anyError[0] = err; console.log(err) })
        if (removedMember) {
            userData.realTeams.forEach(team => {
                if (team._id === teamId) { //SEARCH TEAMNAME IN REALTEAMS
                    team.teamMembers.forEach((member, index) => { //ITINERATE MEMBERS OF TEAM
                        if (member.email === memberToRemove) { //FIND MEMBER
                            team.teamMembers.splice(index, 1); //REMOVE MEMBER FROM TEAMMEMBER ARRAY
                            this.props.reRender();
                        }
                    })
                }
            });
            emitData('removeMember', {
                teamId: teamId,
                memberToRemove: removedMember.data.removeTeamMember,
                requester: userData.user.name
            });
            this.props.startWorking(false);
        } else {
            //ADD EDITED TEAM TO editedTeams TABLE:
            // let editedTeam = userData.realTeams.find(team => { return team._id === teamId });
            // editedTeam.teamMembers.forEach((member, index) => {
            //     if (member.email === memberToRemove) {
            //         editedTeam.teamMembers.splice(index, 1);
            //     }
            // })
            // addTempEditedTeam(editedTeam);
            //.....................................
            startWorking(false);
            console.log(anyError);
            if (anyError[0].networkError) {
                showErrors(true, ["Network error! Can't remove member right now. Please try again later!"]);
            } else {
                if (anyError[0].message === 'GraphQL error: jwt expired') {
                    showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
                    window.myApp.onExitClick();
                } else {
                    showErrors(true, ["Error! Can't remove member right now. Please try again later!"]);
                }
            }
        }
    }

    trashClick = () => {
        this.setState({ remove: !this.state.remove })
    }

    render() {
        let btnStyle = 'ba center pa2 bg-dark-gray b--light-blue dim pointer';
        const { member, userData } = this.props;
        const { remove } = this.state;
        return (
            <div className='ma1' email={member.email}>
                <div className='ba w-50-ns center br3 b--light-blue pointer noselect bg-dark-gray white pa1' key={member.email}>
                    {member.email !== userData.user.email && member.email !== this.props.owner ? <span onClick={this.trashClick} className='far fa-trash-alt mh4 grow pointer pv1 noselect dim w-10 center' style={{ fontSize: 18 + 'px', color: 'red' }} title='Remove member'>del</span> : null}
                    <div className='w-90 center'><b className='center'>{member.name}: {member.email}</b></div>
                </div>
                {remove ? <div className='w-50-ns center tc flex bb bl br br1'>
                    <div className={btnStyle.concat(' light-blue')} onClick={this.trashClick}>CANCEL</div>
                    <div className={btnStyle.concat(' red')} onClick={this.removeUser}>remove</div>
                </div> : null}
            </div>
        )
    }
}
export default MemberToRemove;