import React, { Component } from 'react';

class CreateTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendar: false
        }
    }

    convertDate = (date) => {
        let d = new Date(date);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();

        return (`${day} ${months[month]} ${year}`)
    }

    convertCalendarDate = (date) => {
        let d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();

        return (`${year}, ${month + 1}, ${day}`)
    }

    changeNewOld = () => {
        if (this.props.newOld && (new Date(this.props.events[0].date).getFullYear()) < (new Date()).getFullYear()) { return 'mt4 display-none' }
        else { return 'mt4' }
    }

    onViewClick = () => {
        this.setState({ calendar: !this.state.calendar })
    }

    render() {
        let tdStyle = 'bb w-50 w-third-ns f5-ns f4 grow pointer pt2 pb2 ';
        let tdCityStyle = 'bb f4 w-50 w-third-ns f5-ns f5-ns grow pointer';
        let tdNoStyle = 'bb fw9 f7 gray pl0 pr0 dtc'
        let tdLocationStyle = 'bb f5-ns w-third-ns location-display-none';
        let thStyle = '';
        let thNoStyle = 'f7 gray w1';
        let thLocationStyle = 'pa3 location-display-none';

        let tempEvents = this.props.events;

        tempEvents.sort((a, b) => {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return (dateA - dateB)
        });
        return (
            <div className='w-100'>
                <table className='pa1 mb4 pb4 w-100 tc dt'>
                    <tbody>
                        <tr className='w-100'>
                            <th className={thNoStyle}></th>
                            {/* <th className={thStyle}>Date</th> */}
                            <th className={thStyle}>Type</th>
                            <th className={thStyle}>City</th>
                            <th className={thLocationStyle}>Location</th>
                        </tr>
                        {
                            this.props.events.map((event, i) => (
                                <tr
                                    key={event.date + this.props.events.length + (new Date(tempEvents[0].date).getFullYear()) + i}
                                    className={(new Date(event.date)) < (new Date()) ? ('o-60') : ('')}
                                    uid={event._id}
                                >
                                    <td className={tdNoStyle}><div className='pa0 ma0 noselect'>{i + 1}</div> </td>
                                    {/* <td className={tdStyle} onClick={this.props.options}>{this.convertDate(event.date)}</td> */}
                                    <td className={tdStyle} onClick={this.props.options}>{event.type}</td>
                                    <td className={tdCityStyle} onClick={this.props.options}>{event.city}</td>
                                    <td className={tdLocationStyle}>{event.location}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
export default CreateTable;