import React from "react";
import menuIcon from '../pictures/menu.svg';
import Status from '../screens/Status';

let loggedInStyle = 'light-gray w-50 f4 f3-ns fw9 ma3';
let loggedOutStyle = 'light-blue pointer w-50 tc center grow f2 f1-ns fw9 ma3';
let loggedInHeaderStyle = 'relative ba bg-dark-gray w-80-ns w-90-m center noselect headerStyle2';
let loggedOutHeaderStyle = 'relative ba bg-dark-gray w-80-ns w-90-m center noselect headerStyle';

const HeaderDb = ({ headerClick, isLogged, name, menu, statusMessage }) => {
    return (
        <header className={isLogged ? (loggedInHeaderStyle) : (loggedOutHeaderStyle)} >
            {isLogged ? (<>
                <Status message={statusMessage} />
                <div className="welcomeMessage">Welcome, {name} !</div>
                <img src={menuIcon} className='grow pointer dim headerMenu' onClick={menu} alt=''></img>
            </>
            ) : null}
            <div><h1 className={isLogged ? (loggedInStyle) : (loggedOutStyle)} onClick={headerClick}>Events DB</h1></div>
        </header>
    )
}

export default HeaderDb;