import React, { Component } from 'react';

class TeamSelector extends Component {
    render() {
        return (
            <select name="teams" className='tc dt pa1 ma1 f6 dim pointer noselect shadow-1 fw9 bg-white w-100' onChange={this.props.teamClick}>
                {this.props.teams.length > 1 ? (<option value="allteams" >All Teams</option>) : (null)}

                {this.props.teams.map(team =>
                    <option value={team._id} key={team._id} >{team.teamName} </option>
                )}
            </select>
        )
    }
}
export default TeamSelector;