import idGenerator from 'react-id-generator';

const UserDemoData = {
    user: {
        id: 1111,
        name: 'Mozart Jr.',
        email: 'mozart@events.app',
        role: 'Nice guy'
    },

    realTeams: [
        {
            _id: "111111111111111111111111",
            teamName: 'Napoleon Band',
            teamMembers: [
                {
                    name: 'Mozart Jr.',
                    email: 'mozart@events.app',
                    role: 'Nice guy'
                }
            ],
            owner: "mozart@events.app",
            created: new Date()
        },
        {
            _id: "222222222222222222222222",
            teamName: 'Formatia Cornel Bujor',
            teamMembers: [
                {
                    name: 'Mozart Jr.',
                    email: 'mozart@events.app',
                    role: 'Nice guy'
                }
            ],
            owner: "mozart@events.app",
            created: new Date()
        },
        {
            _id: "333333333333333333333333",
            teamName: 'Ferdinand Band',
            teamMembers: [
                {
                    name: 'Mozart Jr.',
                    email: 'mozart@events.app',
                    role: 'Nice guy'
                }
            ],
            owner: "mozart@events.app",
            created: new Date()
        }
    ],

    realEvents: [
        { _id: idGenerator(), type: 'WEDDING', date: '2017-08-19', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-08-25', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'lorem ipsum lorem ipsum lorem ipsum', team: "111111111111111111111111", earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-07-25', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '400' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2020-07-18', location: 'Pool Party', city: 'New Orleans', price: 2000, earnest: 100, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-08-24', location: "Sun's Park", city: 'New York', price: 3500, earnest: 300, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2021-04-17', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '300' },
        { _id: idGenerator(), type: 'GRADUATION PARTY', date: '2019-06-22', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '400' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-09-14', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-08-23', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-07-25', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2020-07-18', location: 'Pool Party', city: 'New Orleans', price: 2000, earnest: 100, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-08-24', location: "Sun's Park", city: 'New York', price: 3500, earnest: 300, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2021-06-19', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '400' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-06-23', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2017-09-09', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '250' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-08-25', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-08-24', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-05-18', location: 'Pool Party', city: 'New Orleans', price: 2000, earnest: 100, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-06-01', location: "Sun's Park", city: 'New York', price: 3500, earnest: 300, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-11-02', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '300' },
        { _id: idGenerator(), type: 'GRADUATION PARTY', date: '2019-06-15', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: "111111111111111111111111", earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-01-12', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: "222222222222222222222222", earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-12-28', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: "222222222222222222222222", earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-10-24', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: "222222222222222222222222", earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-11-07', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: "222222222222222222222222", earned: '250' },
        { _id: idGenerator(), type: 'GRADUATION PARTY', date: '2020-06-13', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: "222222222222222222222222", earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-01-11', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: "222222222222222222222222", earned: '500' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2020-12-26', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: "222222222222222222222222", earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-10-24', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2021-06-26', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '400' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-10-27', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '500' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2018-06-30', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '400' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-10-27', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-11-03', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '250' },
        { _id: idGenerator(), type: 'GRADUATION PARTY', date: '2020-06-13', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-01-13', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '250' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2018-12-29', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-10-27', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: "333333333333333333333333", earned: '400' }

    ],

    realRecurrent: [
        { eventId: idGenerator(), type: 'Hunters Pub', date: '2019-10-27', location: 'Pub', city: 'Iasi', price: 1000, earnest: 0, description: 'Every wednesday party', team: "333333333333333333333333", earned: '400' }

    ],

    realEarnings: [
        { _id: idGenerator(), type: 'WEDDING', date: '2017-08-19', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-08-25', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'lorem ipsum lorem ipsum lorem ipsum', team: '111111111111111111111111', earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-07-25', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '400' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2020-07-18', location: 'Pool Party', city: 'New Orleans', price: 2000, earnest: 100, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-08-24', location: "Sun's Park", city: 'New York', price: 3500, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2021-04-17', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'GRADUATION PARTY', date: '2019-06-22', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '400' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-09-14', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-08-23', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-07-25', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2020-07-18', location: 'Pool Party', city: 'New Orleans', price: 2000, earnest: 100, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-08-24', location: "Sun's Park", city: 'New York', price: 3500, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2021-06-19', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '400' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-06-23', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2017-09-09', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '250' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-08-25', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-08-24', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-05-18', location: 'Pool Party', city: 'New Orleans', price: 2000, earnest: 100, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-06-01', location: "Sun's Park", city: 'New York', price: 3500, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-11-02', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'GRADUATION PARTY', date: '2019-06-15', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2019-01-12', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2019-12-28', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: '111111111111111111111111', earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-10-24', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: '222222222222222222222222', earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-11-07', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: '222222222222222222222222', earned: '250' },
        { _id: idGenerator(), type: 'GRADUATION PARTY', date: '2020-06-13', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: '222222222222222222222222', earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-01-11', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: '222222222222222222222222', earned: '500' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2020-12-26', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: '222222222222222222222222', earned: '250' },
        { _id: idGenerator(), type: 'WEDDING', date: '2020-10-24', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '300' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2021-06-26', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '400' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-10-27', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '500' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2018-06-30', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '400' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-10-27', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-11-03', location: 'Chong Restaurant', city: 'Tokio', price: 2700, earnest: 200, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '250' },
        { _id: idGenerator(), type: 'GRADUATION PARTY', date: '2020-06-13', location: 'Elysium', city: 'Iasi', price: 3200, earnest: 300, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '300' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-01-13', location: 'Central Hall', city: 'New York', price: 3000, earnest: 300, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '250' },
        { _id: idGenerator(), type: 'ANIVERSARY', date: '2018-12-29', location: 'Wedding Garden', city: 'Chicago', price: 2800, earnest: 200, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '500' },
        { _id: idGenerator(), type: 'WEDDING', date: '2018-10-27', location: 'Central Hall', city: 'New York', price: 4000, earnest: 400, description: 'Some info about this special event.', team: '333333333333333333333333', earned: '400' }

    ]


}

export default UserDemoData;