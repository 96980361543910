import React, { PureComponent } from 'react';
import { Doughnut } from 'react-chartjs-2';

const data =
{
    labels: [],
    datasets: [
        {
            label: 'Income',
            backgroundColor: ['#a3ff8f', '#ff691f', '#00cfd6', '#52bdff', '#a3ff8f', '#ff691f', '#00cfd6', '#52bdff', '#a3ff8f', '#ff691f', '#00cfd6', '#52bdff'],
            borderColor: 'white',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: []
        }
    ]
}

class EventsChart extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: data
        }
    }

    createData = (events) => {
        function reduceFunction(total, sum) {
            return total + sum;
        }

        let types = []; //changed from teams
        events.forEach(event => { types.push(event.type) })
        let uniqueTypes = Array.from(new Set(types));
        data.labels = uniqueTypes;

        data.datasets[0].data = [];
        data.labels.forEach((type, i) => {
            let tempArr = [];
            events.forEach(event => {
                if (type === event.type) {
                    tempArr.push(Number(event.earned));
                }
            })
            if (tempArr.length > 0) {
                data.datasets[0].data[i] = tempArr.reduce(reduceFunction, 0);
            } else data.datasets[0].data[i] = 0;
        })
    }

    render() {
        const { events } = this.props;
        this.createData(events)
        return (
            <div className='w-100 mt3 bb'>
                <h3 className='tc'>Earning by event types </h3>
                <Doughnut
                    height={125}
                    data={this.state.data}
                    redraw={true}
                />
            </div>
        )
    }
}

export default EventsChart;