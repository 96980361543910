import React, { Component } from 'react';
import NavBar from './NavBar';
import Teams from './Teams/Teams';
import Events from './Events/Events';
import Money from './Money/Money';
import Menu from './Menu/Menu';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            route: props.newRoute,
        }
    }


    wrapperDemoClick = () => {
        this.props.demo();
        this.setState({ route: 'events' });
    }

    switchState = (type) => {
        const { userData, showConfirmation, startWorking, showErrors, createClick, users, teams, addNewTeam, demoState, onLoginSubmit, onUserNameChange, exit } = this.props;
        switch (type) {
            case 'events': return <Events showConfirmation={showConfirmation} showErrors={showErrors} startWorking={startWorking} userData={userData} createClick={createClick} ref={(myEvents) => { window.myEvents = myEvents }} />
            case 'money': return <Money showConfirmation={showConfirmation} showErrors={showErrors} startWorking={startWorking} userData={userData} createClick={createClick} />
            case 'teams': return <Teams showConfirmation={showConfirmation} showErrors={showErrors} startWorking={startWorking} userData={userData} users={users} teams={teams} addNewTeam={addNewTeam} onLoginSubmit={onLoginSubmit} />
            case 'menu': return <Menu showConfirmation={showConfirmation} showErrors={showErrors} startWorking={startWorking} userData={userData} exit={exit} demo={this.wrapperDemoClick} demoState={demoState} onUserNameChange={onUserNameChange} />
            case 'noData': return <p>WAITING FOR DATA...</p>
            default: return <p>No Navigation Route selected</p>
        }
    }

    render() {
        const { exit } = this.props;
        const { route } = this.state;

        return (
            <section>
                <NavBar selected={route} exit={exit} events={this.props.onEventsClick} income={this.props.onMoneyClick} teams={this.props.onTeamsClick} menu={this.props.onMenuClick} profile={this.onProfileClick} />
                <article className="relative center w-100 baskerville pa2">
                    {this.switchState(route)}

                </article>
            </section>

        )
    }
}
export default Navigation;