import React, { Component } from 'react';
import { addEarnValue, editEarning, removeEarning } from '../../queries/queries';
import editIcon from '../../pictures/edit-2.svg';
import deleteIcon from '../../pictures/trash-2-small.svg';
import upIcon from '../../pictures/chevron-up-white.svg';
import downIcon from '../../pictures/chevron-down-blue.svg';
import { db, tempDb } from '../../queries/db';
import { addTempEditedEarning, addTempRemovedEarning } from '../../queries/temporary';

class Earning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            reRender: false,
            details: false,
            toDelete: false
        }
    }

    onAddClick = async (e) => {
        let id = e.target.parentNode.parentNode.getAttribute('uid');
        let input = document.getElementById('addinput'.concat(id));
        const { startWorking, showErrors, userData } = this.props;
        let authToken = userData.user.authToken;
        if (input.value.length > 0) {
            startWorking(true, 'Adding earning...');
            let anyError = [];
            let addedEarn = await addEarnValue(id, Number(input.value.trim()), userData.user.email, authToken).then(result => { return result }).catch(err => { anyError[0] = err; console.log(err) });
            if (addedEarn) {
                userData.realEarnings.forEach(event => {
                    if (event._id === id) {
                        event.earned = input.value.trim();
                    }
                    this.setState({ reRender: !this.state.reRender })
                });
                db.earnings.update(id, { earned: input.value.trim() })
                startWorking(false);
            } else {
                //....
                let earningToAdd = userData.realEarnings.find(earning => { return earning._id === id });
                earningToAdd.earned = Number(input.value.trim());
                await tempDb.editedEarnings.put(earningToAdd);
                //....
                startWorking(false);
                console.log(anyError);
                if (anyError[0].networkError) {
                    showErrors(true, ["Network error! Can't add this recurrent. Please try again later!"]);
                } else {
                    showErrors(true, ["Error! Can't add this recurrent. Please try again later!"])
                }
            }
        }
        this.props.reRender();
    }


    onSubmitEdit = async (e) => {
        const { userData, startWorking, showErrors } = this.props;
        if (this.state.edit) {
            let id = e.target.getAttribute('uid');
            let input = document.getElementById('editinput'.concat(id));
            let descriptionInput = document.getElementById('descriptioninput'.concat(id));
            let cityInput = document.getElementById('cityinput'.concat(id));
            let typeInput = document.getElementById('typeinput'.concat(id));
            let authToken = userData.user.authToken;
            if (input.value.length > 0) {
                let anyError = [];
                startWorking(true, 'Editing earning...');
                let editedEarning = await editEarning(id, typeInput.value, userData.user.email, cityInput.value, Number(input.value), descriptionInput.value, authToken).catch(err => { anyError[0] = err; console.log(err) });
                if (editedEarning) {
                    let args = editedEarning.data.editEarning;
                    userData.realEarnings.forEach((earning, i) => {
                        if (earning._id === args._id) {
                            userData.realEarnings[i] = args;
                            this.setState({ reRender: !this.state.reRender });
                        }
                    });
                    db.earnings.put(editedEarning.data.editEarning);
                    startWorking(false);
                } else {
                    addTempEditedEarning(id, typeInput.value, userData.user.email, cityInput.value, Number(input.value), descriptionInput.value);
                    startWorking(false);
                    console.log(anyError);
                    if (anyError[0].networkError) {
                        showErrors(true, ["Network error! Can't edit this recurrent. Please try again later!"]);
                    } else {
                        if (anyError[0].message === 'GraphQL error: jwt expired') {
                            showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
                            window.myApp.onExitClick();
                        } else {
                            showErrors(true, ["Error! Can't edit this recurrent. Please try again later!"])
                        }
                    }
                }

                this.props.reRender();
                this.setState({ edit: !this.state.edit });
            } else {
                input.setAttribute('style', 'border-color: red')
            }

        }

    }

    onEditClick = async (e) => {
        this.setState({ edit: !this.state.edit });
    }

    onEnterKeyAdd = (e) => {
        if (e.keyCode === 13) { this.onAddClick(e) }
    }

    onEnterKeyEdit = (e) => {
        if (e.keyCode === 13) { this.onEditClick(e) }
    }

    onTdClick = () => {
        this.setState({
            details: !this.state.details,
            toDelete: false
        })
        if (this.state.edit) {
            this.setState({ edit: !this.state.edit })
        }
    }

    convertDate = (date) => {
        let d = new Date(date);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();

        return (`${months[month]} ${day} ${year} `)
    }

    onDeleteClick = () => {
        this.setState({ toDelete: !this.state.toDelete })
        console.log('delete clicked')
    }

    onDeleteConfirm = async (e) => {
        this.props.startWorking(true, 'Deleting earning...');
        let id = e.target.getAttribute('uid')
        const { userData, startWorking, showErrors } = this.props;
        let anyError = [];
        let authToken = userData.user.authToken;
        let removedEarning = await removeEarning(userData.user.email, id, authToken).catch(err => { anyError[0] = err; console.log(err) });
        if (removedEarning) {
            userData.realEarnings.forEach((event, i) => {
                if (event._id === removedEarning.data.removeEarning._id) {
                    userData.realEarnings.splice(i, 1);
                    this.props.reRender();
                }
            });
            db.earnings.delete(id);
            this.props.onSelectedYear();
            this.props.startWorking(false);
        } else {
            let earningToRemove = userData.realEarnings.find(earning => { return earning._id === id });
            addTempRemovedEarning(earningToRemove);
            startWorking(false);
            console.log(anyError);
            if (anyError[0].networkError) {
                showErrors(true, ["Network error! Can't edit this recurrent. Please try again later!"]);
            } else {
                showErrors(true, ["Error! Can't edit this recurrent. Please try again later!"])
            }
        }
    }

    render() {
        const { event } = this.props;
        const { edit, details, toDelete } = this.state;
        let inputStyle = 'w-70-ns w-100 center ma1 tc';

        return (
            <div>
                <div uid={event._id} className={details ? ('w-100 flex tc bg-light-blue mt2 white') : 'w-100 flex tc blue'}>
                    <div className='w-20' onClick={this.onTdClick}>{details ? (<img src={upIcon} className='pointer' alt='' />) : (<img src={downIcon} className='pointer' alt='' />)}</div>
                    <div className='w-30'>{this.convertDate(event.date)}</div>
                    <div className='w-30'>{edit ? (<input type='text' name='city' defaultValue={event.type} id={'typeinput'.concat(`${event._id}`)} size='7' className={inputStyle} placeholder='type'></input>) : event.type}</div>
                    <div className='w-20'>
                        {event.earned === 0 ?
                            (
                                <>
                                    <input id={'addinput'.concat(event._id)} type='number' className={inputStyle} placeholder='earn' onKeyDown={this.onEnterKeyAdd}></input>
                                    <button onClick={this.onAddClick}>ADD</button>
                                </>)
                            :
                            (edit ?

                                <input uid={event._id} id={'editinput'.concat(`${event._id}`)} className={inputStyle} onKeyDown={this.onEnterKeyEdit} defaultValue={event.earned} type='number' placeholder='earn'></input>
                                : event.earned)}
                    </div>
                </div>
                {details ? (
                    <div uid={event._id} className='w-100 flex tc pa1 mb2 bg-light-blue white'>
                        <div className='w-20'>{edit ? (<input defaultValue={event.city} id={'cityinput'.concat(`${event._id}`)} size='7' placeholder='city'></input>) : event.city} </div>
                        <div className='w-30'>{event.team}</div>
                        <div className='w-30'>{edit ? <textarea rows='2' cols='15' id={'descriptioninput'.concat(`${event._id}`)} defaultValue={event.description} placeholder='description'></textarea> : event.description}</div>
                        <div className='w-20'>
                            {event.earned ? (
                                <div>{edit ? <p uid={event._id} onClick={this.onSubmitEdit} className="noselect pointer dim ma0 pa0">OK</p> : <img src={editIcon} uid={event._id} onClick={this.onEditClick} className='noselect pointer dim ma2' alt='' />} </div>
                            ) : (null)}
                            <img src={deleteIcon} onClick={this.onDeleteClick} className='grow poiner noselect dim cener ma2' alt='' />
                        </div>
                    </div>
                ) : (null)}
                {toDelete ? (
                    <div className='w-100 tc flex'>
                        <div onClick={this.onDeleteClick} className='w-50 ba center pa2 bg-dark-gray b--light-blue dim pointer white'>Cancel</div>
                        <div onClick={this.onDeleteConfirm} uid={event._id} className='w-50 ba center pa2 bg-dark-gray b--light-blue dim pointer red'>Delete</div>
                    </div>
                ) : (null)}
            </div>
        )
    }
}
export default Earning;