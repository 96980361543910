import React, { Component } from 'react';
import MemberToRemove from './MemberToRemove';

class RemoveMember extends Component {

    render() {
        return (
            <div className='ma1'>
                <p className='ma1'>Members:</p>
                {this.props.teamMembers.map(member =>
                    <MemberToRemove showErrors={this.props.showErrors} startWorking={this.props.startWorking} member={member} key={member.email} userData={this.props.userData} reRender={this.props.reRender} owner={this.props.owner} />
                )}
            </div>
        )
    }
}
export default RemoveMember;