import React from 'react';
import LoginRegister from './LoginRegister';
import ImageBackground from './ImageBackground';
import smallPencilPicture from '../pictures/PencilBackground640x960.jpg';
import bigPencilPicture from '../pictures/PencilsBackground1280x1920.jpg';

const Home = ({ blabla, blabla2 }) => {
    return (
        <div>
            <section className='relative'>
                <LoginRegister clickLogin={blabla} clickRegister={blabla2} />

                <article className=''>
                    <h3 className='tc'>YOUR</h3>
                    <h2 className='tc'>EVENTS </h2>
                    <h1 className='tc'>DATABASE</h1>
                    <h4 className='tc'>Manage and share events with your team</h4>
                </article>
                <div className='flex mw7 center '>
                </div>
            </section>
            <ImageBackground
                imageUrl={window.screen.width >= 992 ? (bigPencilPicture) : (smallPencilPicture)}
                cssClass='imageBackground o-60 h-100 w-100 center'
            />
        </div>
    )
}
export default Home;