import React, { PureComponent } from 'react';
import { Bar } from 'react-chartjs-2';

const data =
{
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Income',
            backgroundColor: '#96CCFF',
            borderColor: 'white',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
    ]
}

class YearChart extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: data
        }
    }

    createData = (events) => {
        function reduceFunction(total, sum) {
            return total + sum;
        }

        data.labels.forEach((month, i) => {
            let tempArr = [];
            events.forEach(event => {
                if (month === data.labels[new Date(event.date).getMonth()]) {
                    tempArr.push(Number(event.earned))
                }
            })
            if (tempArr.length > 0) {
                data.datasets[0].data[i] = tempArr.reduce(reduceFunction, 0);
            } else data.datasets[0].data[i] = 0;
        })
    }


    render() {
        const { events } = this.props;
        this.createData(events)

        return (
            <div className='w-100 mt3 bb'>
                <h3 className='tc'>Earning by month</h3>
                <Bar
                    height={125}
                    data={this.state.data}
                    redraw={true}
                />
            </div>
        )
    }
}
export default YearChart;