import { tempDb, db } from '../queries/db';
import userData from '../components/UserData';
import nextId from 'react-id-generator';

function createId(string) {
    let id = string + Math.round(Math.random() * 1000000000000);
    return id;
}

const addTempTeam = async (teamName) => {
    let tempTeam = {
        _id: nextId('temp-team-'),
        teamName: teamName,
        owner: userData.user.email,
        teamMembers: [
            {
                _id: userData.user.id,
                name: userData.user.name,
                role: userData.user.role,
                email: userData.user.email,
                level: userData.user.level
            }
        ]

    }
    await tempDb.addedTeams.put(tempTeam);
    userData.realTeams.push(tempTeam);
}

const addTempEvent = async (type, date, team, owner, location, city, price, earned, earnest, description, created, author, authorEmail, editAuthor, editAuthorEmail, modified) => {
    let tempAddedEvent = {
        _id: createId('temp-event-'),
        type: type,
        date: date,
        location: location,
        city: city,
        price: price,
        earnest: earnest,
        description: description,
        team: team,
        created: created,
        earned: earned,
        owner: owner,
        author: author,
        authorEmail: authorEmail,
        editAuthor: editAuthor,
        editAuthorEmail: editAuthorEmail,
        modified: modified
    }
    await tempDb.addedEvents.put(tempAddedEvent);
    userData.realEvents.push(tempAddedEvent);

}

const addTempEarning = async (type, date, team, owner, location, city, price, earned, earnest, description) => {
    await tempDb.addedEarnings.put(
        {
            _id: nextId('temp-earning-'),
            type: type,
            date: date,
            location: location,
            city: city,
            price: price,
            earnest: earnest,
            description: description,
            team: team,
            earned: earned,
            owner: owner,
        }
    )
}

const addTempRecurrent = async (type, date, team, owner, location, city, price, earned, earnest, description) => {
    await tempDb.addedRecurrent.put({
        _id: nextId('temp-recurrent-'),
        type: type,
        date: date,
        location: location,
        city: city,
        price: price,
        earnest: earnest,
        description: description,
        team: team,
        earned: earned,
        owner: owner,
    })
}

const addTempEditedEvent = async (id, type, date, team, owner, location, city, price, earnest, description, authorEmail, editAuthor, editAuthorEmail, modified) => {
    let editedEvent = {
        _id: id,
        type: type,
        date: date,
        location: location,
        city: city,
        price: price,
        earnest: earnest,
        description: description,
        team: team,
        owner: owner,
        authorEmail: authorEmail,
        editAuthor: editAuthor,
        editAuthorEmail: editAuthorEmail,
        modified: modified
    };
    userData.realEvents.forEach((event, index) => {
        if (event._id === editedEvent._id) {
            userData.realEvents.splice(index, 1);
            userData.realEvents.push(editedEvent);
        }
    });


    if (id.indexOf('temp') > -1) {
        await tempDb.addedEvents.put(editedEvent);
    } else {
        editedEvent._id = 'temp-edited-' + id;
        await db.events.delete(id);
        await tempDb.editedEvents.put(editedEvent);
    }
}

const addTempEditedEarning = async (id, type, owner, city, earned, description) => {
    await tempDb.editedEarnings.put(
        {
            _id: id,
            type: type,
            city: city,
            description: description,
            owner: owner,
            earned: earned
        }
    )
}

const addTempEditedTeam = async (team) => {
    await tempDb.editedTeams.put(team)
}

const addTempEditedUser = async (user) => {
    const { id, name, email, role } = user;
    await tempDb.editedUser.put({
        id: id,
        name: name,
        email: email,
        role: role
    });
}

const addTempRemovedEarning = async (earning) => {
    await tempDb.removedEarnings.put(earning);
}
const addTempRemovedEvent = async (event) => {
    await tempDb.removedEvents.put(event);
    userData.realEvents.forEach((ev, index) => {
        if (ev._id === event._id) {
            userData.realEvents.splice(index, 1);
        }
    });
    if (event._id.indexOf('temp') > -1) {
        await tempDb.addedEvents.delete(event._id);
    } else {
        await db.events.delete(event._id);
    }
}
const addTempRemovedTeam = async (team) => {
    await tempDb.removedTeams.put(team);
}



export {
    addTempTeam, addTempEvent, addTempEarning, addTempRecurrent, addTempEditedEvent, addTempEditedEarning,
    addTempEditedTeam, addTempEditedUser, addTempRemovedEarning, addTempRemovedEvent, addTempRemovedTeam
};