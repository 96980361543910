import React, { Component } from 'react';
import CreateTable from './CreateTable';

class MultipleTables extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    extractYears = (events) => {
        let years = [];
        events.forEach(event => {
            let year = new Date(event.date);
            if (!years.includes(year.getFullYear())) {
                years.push(year.getFullYear())
            }
        })
        return (years);
    }

    render() {
        let orderedEvents = this.props.events;
        orderedEvents.sort((a, b) => {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return (dateA - dateB)
        });
        let years = this.extractYears(orderedEvents);

        return (
            <div>
                {this.props.events.length > 0 ? (
                    years.map(year => {
                        let eventsByYear = [];
                        orderedEvents.forEach(event => {
                            let date = (new Date(event.date).getFullYear());
                            if (year === date) {
                                eventsByYear.push(event);
                            }
                        })
                        return <CreateTable events={eventsByYear} key={year} newOld={this.props.newOld} options={this.props.options} dateClicked={this.props.dateClicked} userData={this.props.userData} />
                    })
                ) : (<div className='ma5 light-blue'>
                    <h3 className='tc f2 noselect'>Cool !</h3>
                    <h4 className='tc f2 noselect'>Let's get started!</h4>
                    <h4 className='tc f2 noselect'>Add first event to this team!</h4>
                </div>
                    )}
            </div>
        )
    }
}

export default MultipleTables;