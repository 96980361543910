import React, { Component } from 'react';
import EditEntry from './EditEntry';

function convertDate(date) {
    let d = new Date(date);
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();

    return (`${day} ${months[month]} ${year}`)
}

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'details'
        }
    }

    promptDel = () => {
        this.setState({
            type: 'prompt'
        })
    }
    answerNo = () => {
        this.setState({
            type: 'details'
        })
    }

    edit = () => {
        this.setState({
            type: 'edit'
        })
    }
    closeEdit = () => {
        this.setState({
            type: 'details'
        })
    }

    switchState = (type) => {
        const { event, stateTeam, teams, saveEvent } = this.props;
        switch (type) {
            case 'details': return (
                <div>
                    <div className='pa2'>
                    <div className='w-100 flex justify-between center' >
                        <div className='ba br3  tc pa1 ma3 f6 dim pointer noselect shadow-4 fw9' style={{width: 120}} onClick={this.edit}>EDIT</div>
                        <div className='ba br3  tc pa1 ma3 f6 dim pointer noselect shadow-4 fw9' style={{width: 120}} onClick={this.promptDel} uid={event[0].eventId}>DELETE</div>
                    </div>
                        <div className='f4 fw9 word-break mb3 underline pa2'> {event[0].type}</div>
                        <div className='f2 mb3'> {convertDate(event[0].date)} </div>
                        <div className='f3 fw8'> {event[0].location}</div>
                        <div className='f2 fw8 mb3'>{event[0].city ? event[0].city.toUpperCase() : ""}</div>
                        <div className='f3 mb3'>Price: {event[0].price}/{event[0].earnest}</div>
                        <div className='f3 pa0 fw9'>Details: </div>
                        <p className='f3 w-100 relative ma0 mb2'>{event[0].description}</p>
                        <div className='f4 fw9 word-break mb3 underline pa2'> {event[0].team.teamName}</div>
                        {event[0].author ? <div className='f6 ma0 pa0 gray'>Created: {convertDate(event[0].created)} by {event[0].author} ({event[0].authorEmail}) </div> : (null)}
                        {event[0].editAuthor ? <div className='f6 ma0 pa0 gray'>Last edited: {convertDate(event[0].modified)} by {event[0].editAuthor} ({event[0].editAuthorEmail}) </div> : (null)}
                    </div>
                    <div className='ba br3  tc pa1 mb3 f4 dim pointer center noselect shadow-4 fw9 w-50' onClick={this.props.closeDiv}>Close</div>

                </div>
            );
            case 'prompt': return (
                <div className='f3'>
                    <p>You will permanently DELETE this {this.props.event[0].type} event !</p>
                    <h2> Are you sure ?</h2>
                    <div className='flex center w-50'>
                        <div className='w-50 f3'><button onClick={this.answerNo} >No</button></div>
                        <div className='w-50 f3'><button onClick={this.props.del} uid={event[0]._id}>Yes</button></div>
                    </div>
                </div>);
            case 'edit': return (<EditEntry display={true} closeDiv={this.closeEdit}
                type={event[0].type} date={event[0].date} location={event[0].location} city={event[0].city}
                price={event[0].price} earnest={event[0].earnest} description={event[0].description} uid={event[0]._id}
                team={event[0].team}
                saveEvent={saveEvent}
                stateTeam={stateTeam}
                teams={teams}
            />);
            default: return <p>No Route selected</p>
        }
    }
    render() {
        const { type } = this.state;
        return (
            <div className='pa3 bg-dark-gray bg-options w-100 dt tc center'>
                <div className='pa2 bg-near-white details w-90 center tc  dtc v-mid h-100'>
                    {this.switchState(type)}
                </div>
            </div>
        )
    }
}
export default Details;