import React, { Component } from 'react';
import moreVerticalIcon from '../../pictures/more-vertical.svg';

let clickedOptionStyle = 'tc f6 dim pointer noselect bg-light-blue br3 pt2';
let unclickedOptionStyle = 'tc f6 dim pointer noselect gray pt2';

class Dropdown extends Component {

    render() {
        return (
            <div className={this.props.dropStyle ? clickedOptionStyle : unclickedOptionStyle}>
                {/* <div className={this.props.dropStyle ? clickedOptionStyle : unclickedOptionStyle} ></div> */}
                <img onClick={this.props.optionClick} src={moreVerticalIcon} className="pointer" title='OPTIONS' alt=''/>
            </div>
        )
    }

}

export default Dropdown