import { gql } from 'apollo-boost';
import ApolloClient from 'apollo-boost';

const client = new ApolloClient({
    uri: process.env.REACT_APP_LOCAL_GRAPHQL ? (process.env.REACT_APP_LOCAL_GRAPHQL) : ('https://sleepy-dawn-33746.herokuapp.com/graphql')
});

//GET USER BY EMAIL:

const generateJWT = async (email, password) => {
    //GRAPHQL QUERY:
    const GENERATE_JWT_QUERY = gql`
        query($email: String!, $password: String!){
            generateJWT(email: $email, password: $password)
        }`
    //FETCHING DATA:
    let result = await client.query({
        query: GENERATE_JWT_QUERY,
        variables: { email, password }
    });
    return result;
}

const addUser = async (name, email, password, role) => {
    //GRAPHQL QUERY:
    const ADD_USER_MUTATION = gql`
        mutation ($name: String!, $email: String!, $password: String!, $role: String){
        addUser(name: $name, email: $email, password: $password, role: $role){
            email
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: ADD_USER_MUTATION,
        variables: { name, email, password, role }
    });
    return result;
}

const removeUser = async (email, password) => {
    //GRAPHQL QUERY:
    const REMOVE_USER_MUTATION = gql`
        mutation ($email: String!, $password: String!){
        removeUser(email: $email, password: $password) {
            email
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: REMOVE_USER_MUTATION,
        variables: { email, password }
    });
    return result;
}
const getUser = async (email, authToken) => {
    //GRAPHQL QUERY:
    const GET_USER_QUERY = gql`
        query User($email: String!, $authToken: String!){
            user(email: $email, authToken: $authToken) {
                _id
                name
                email
                role
                password
                recurrent {
                        _id
                        type
                        date
                        location
                        city
                        price
                        earnest
                        description
                        team
                        created
                        earned
                        owner
                    }
                    teams {
                        _id
                        teamName
                        owner
                    }
                    earnings {
                        _id
                        type
                        date
                        location
                        city
                        price
                        earnest
                        description
                        team
                        created
                        earned
                        owner
                    }
            }
}`;
    //FETCHING DATA:
    let result = await client.query({
        query: GET_USER_QUERY,
        variables: { email, authToken }
    })
    return result;
}

const getUserEvents = async (email, authToken) => {
    //GRAPHQL QUERY:
    const GET_USER_EVENTS_QUERY = gql`
        query ($email: String!, $authToken: String!){
        getUserEvents(userEmail: $email, authToken: $authToken) {
            _id
            type
            date
            location
            city
            price
            earnest
            description
            team
            created
            earned
            owner
            author
            authorEmail
            editAuthor
            editAuthorEmail
            modified
            }    
        }`
    //FETCHING DATA:
    let result = await client.query({
        query: GET_USER_EVENTS_QUERY,
        variables: { email, authToken }
    })
    return result;
}

const getUserTeams = async (email, authToken) => {
    //GRAPHQL QUERY:
    const GET_USER_TEAMS_QUERY = gql`
        query ($email: String!, $authToken: String!){
        getUserTeams(userEmail: $email, authToken: $authToken) {
    _id
    teamName
    owner
    teamMembers {
      _id
      name
      role
      email
      level
    }
} }`
    //FETCHING DATA:
    let result = await client.query({
        query: GET_USER_TEAMS_QUERY,
        variables: { email, authToken }
    });
    return result;
}

const editUserName = async (email, newName, authToken) => {
    //GRAPHQL QUERY:
    const EDIT_USER_NAME_MUTATION = gql`
        mutation ($email: String!, $newName: String!, $authToken: String!){
        editUserName(ownerEmail: $email, newName: $newName, authToken: $authToken)  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: EDIT_USER_NAME_MUTATION,
        variables: { email, newName, authToken }
    });
    return result;
}

const editUserRole = async (email, newRole, authToken) => {
    //GRAPHQL QUERY:
    const EDIT_USER_ROLE_MUTATION = gql`
        mutation ($email: String!, $newRole: String!, $authToken: String!){
        editUserRole(ownerEmail: $email, newRole: $newRole, authToken: $authToken)  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: EDIT_USER_ROLE_MUTATION,
        variables: { email, newRole, authToken }
    });
    return result;
}

const addTeamMember = async (memberEmail, teamId, ownerEmail, authToken) => {
    //GRAPHQL QUERY:
    const ADD_TEAM_MEMBER_MUTATION = gql`
        mutation ($memberEmail: String!, $teamId: String!, $ownerEmail: String!, $authToken: String!){
        addTeamMember(memberEmail: $memberEmail, teamId: $teamId, ownerEmail: $ownerEmail, authToken: $authToken) {
            _id
            name
            role
            email
            level
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: ADD_TEAM_MEMBER_MUTATION,
        variables: { memberEmail, teamId, ownerEmail, authToken }
    });
    return result;
}

const removeTeamMember = async (requester, memberEmail, teamId, ownerEmail, authToken) => {
    //GRAPHQL QUERY:
    const REMOVE_TEAM_MEMBER_MUTATION = gql`
        mutation ($requester: String!, $memberEmail: String!, $teamId: String!, $ownerEmail: String!, $authToken: String!){
        removeTeamMember(requester: $requester, memberEmail: $memberEmail, teamId: $teamId, ownerEmail: $ownerEmail, authToken: $authToken) {
            email
            name
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: REMOVE_TEAM_MEMBER_MUTATION,
        variables: { requester, memberEmail, teamId, ownerEmail, authToken }
    });
    return result;
}

const addEvent = async (type, date, team, owner, location, city, price, earned, earnest, description, created, author, authorEmail, editAuthor, editAuthorEmail, modified, authToken) => {
    //GRAPHQL QUERY:
    const ADD_EVENT_MUTATION = gql`
        mutation ($type: String!, $date: String!, $team: String!, $owner: String!, $location: String, $city: String, $price: Int, $earned: Int, $earnest: Int, $description: String, $created: String, $author: String, $authorEmail: String, $editAuthor: String, $editAuthorEmail: String, $modified: String, $authToken: String!){
        addEvent(type: $type, date: $date, team: $team, owner: $owner, location: $location, city: $city, price: $price, earned: $earned, earnest: $earnest, description: $description, created: $created, author: $author, authorEmail: $authorEmail, editAuthor: $editAuthor, editAuthorEmail: $editAuthorEmail, modified: $modified, authToken: $authToken) {
            _id
            type
            date
            location
            city
            price
            earnest
            description
            team
            created
            earned
            owner
            author
            authorEmail
            editAuthor
            editAuthorEmail
            modified
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: ADD_EVENT_MUTATION,
        variables: { type, date, team, owner, location, city, price, earned, earnest, description, created, author, authorEmail, editAuthor, editAuthorEmail, modified, authToken }
    });
    return result;
}

const removeEvent = async (requester, eventId, teamId, authToken) => {
    //GRAPHQL QUERY:
    const REMOVE_EVENT_MUTATION = gql`
        mutation ($requester: String!, $eventId: String!, $teamId: String!, $authToken: String!){
        removeEvent(requester: $requester, eventId: $eventId, teamId: $teamId, authToken: $authToken) {
            _id
            type
            team
            author
            date
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: REMOVE_EVENT_MUTATION,
        variables: { requester, eventId, teamId, authToken }
    });
    return result;
}

const editEvent = async (id, type, date, team, owner, location, city, price, earnest, description, authorEmail, editAuthor, editAuthorEmail, modified, authToken) => {
    //GRAPHQL QUERY:
    const EDIT_EVENT_MUTATION = gql`
        mutation ($id: String! ,$type: String, $date: String, $team: String, $owner: String!, $location: String, $city: String, $price: Int,  $earnest: Int, $description: String, $authorEmail: String, $editAuthor: String, $editAuthorEmail: String, $modified: String, $authToken: String!){
        editEvent(_id: $id, type: $type, date: $date, team: $team, owner: $owner, location: $location, city: $city, price: $price,  earnest: $earnest, description: $description, authorEmail: $authorEmail, editAuthor: $editAuthor, editAuthorEmail: $editAuthorEmail, modified: $modified, authToken: $authToken) {
            _id
            type
            date
            location
            city
            price
            earnest
            description
            team
            created
            earned
            owner
            author
            authorEmail
            editAuthor
            editAuthorEmail
            modified
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: EDIT_EVENT_MUTATION,
        variables: { id, type, date, team, owner, location, city, price, earnest, description, authorEmail, editAuthor, editAuthorEmail, modified, authToken }
    });
    return result;
}

const addRecurrent = async (type, date, team, owner, location, city, price, earned, earnest, description, authToken) => {
    //GRAPHQL QUERY:
    const ADD_RECURRENT_MUTATION = gql`
        mutation ($type: String!, $date: String!, $team: String!, $owner: String!, $location: String, $city: String, $price: Int, $earned: Int, $earnest: Int, $description: String, $authToken: String!){
        addRecurrent(type: $type, date: $date, team: $team, owner: $owner, location: $location, city: $city, price: $price, earned: $earned, earnest: $earnest, description: $description, authToken: $authToken) {
            _id
            type
            date
            team
            owner
            location
            city
            price
            earnest
            description
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: ADD_RECURRENT_MUTATION,
        variables: { type, date, team, owner, location, city, price, earned, earnest, description, authToken }
    });
    return result;
}

const removeRecurrent = async (email, authToken) => {
    //GRAPHQL QUERY:
    const REMOVE_RECURRENT_MUTATION = gql`
        mutation($email: String!, $authToken: String!) {
            removeRecurrent(requester: $email, authToken: $authToken)
        }
    `
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: REMOVE_RECURRENT_MUTATION,
        variables: { email, authToken }
    });
    return result;
}

const addEarning = async (type, date, team, owner, location, city, price, earned, earnest, description, authToken) => {
    //GRAPHQL QUERY:
    const ADD_EARNING_MUTATION = gql`
        mutation ($type: String!, $date: String!, $team: String!, $owner: String!, $location: String, $city: String, $price: Int, $earned: Int!, $earnest: Int, $description: String, $authToken: String!){
        addEarning(type: $type, date: $date, team: $team, owner: $owner, location: $location, city: $city, price: $price, earned: $earned, earnest: $earnest, description: $description, authToken: $authToken) {
            _id
            type
            date
            team
            owner
            location
            city
            price
            earnest
            earned
            description
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: ADD_EARNING_MUTATION,
        variables: { type, date, team, owner, location, city, price, earned, earnest, description, authToken }
    });
    return result;
}

const addEarnValue = async (earningId, earned, owner, authToken) => {
    //GRAPHQL QUERY:
    const ADD_EARN_VALUE_MUTATION = gql`
        mutation($earningId: String!, $earned: Int!, $owner: String!, $authToken: String!) {
            addEarnValue(earningId: $earningId, earned: $earned, owner: $owner, authToken: $authToken)
        }
    `
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: ADD_EARN_VALUE_MUTATION,
        variables: { earningId, earned, owner, authToken }
    });
    return result;
}

const removeEarning = async (email, earningId, authToken) => {
    //GRAPHQL QUERY:
    const REMOVE_EARNING_MUTATION = gql`
        mutation($email: String!, $earningId: String!, $authToken: String!) {
            removeEarning(requester: $email, earningId: $earningId, authToken: $authToken) {
                _id
            }
        }
    `
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: REMOVE_EARNING_MUTATION,
        variables: { email, earningId, authToken }
    });
    return result;
}

const editEarning = async (id, type, owner, city, earned, description, authToken) => {
    //GRAPHQL QUERY:
    const EDIT_EARNING_MUTATION = gql`
        mutation ($id: String! ,$type: String, $owner: String!, $city: String, $earned: Int, $description: String, $authToken: String!){
        editEarning(_id: $id, type: $type, owner: $owner, city: $city, earned:$earned, description: $description, authToken: $authToken) {
            _id
            type
            date
            team
            owner
            location
            city
            description
            earned
        }  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: EDIT_EARNING_MUTATION,
        variables: { id, type, owner, city, earned, description, authToken }
    });
    return result;
}

const addTeam = async (teamName, owner, authToken) => {
    //GRAPHQL QUERY:
    const ADD_TEAM_MUTATION = gql`
        mutation($teamName: String!, $owner: String!, $authToken: String!) {
            addTeam(teamName: $teamName, owner: $owner, authToken: $authToken) {
                _id
 	            teamName
 	            owner
                teamMembers {
                _id
                name
                role
                email
                level
                }
            }
        }
    `
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: ADD_TEAM_MUTATION,
        variables: { teamName, owner, authToken }
    });
    return result;
}
const editTeamName = async (userEmail, teamId, newTeamName, authToken) => {
    //GRAPHQL QUERY:
    const EDIT_TEAM_NAME_MUTATION = gql`
        mutation ($userEmail: String!, $teamId: String!, $newTeamName: String!, $authToken: String!){
        editTeamName(userEmail: $userEmail, teamId: $teamId, newTeamName: $newTeamName, authToken: $authToken)  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: EDIT_TEAM_NAME_MUTATION,
        variables: { userEmail, teamId, newTeamName, authToken }
    });
    return result;
}

const removeTeam = async (teamId, owner, requester, authToken) => {
    //GRAPHQL QUERY:
    const REMOVE_TEAM_MUTATION = gql`
        mutation($teamId: String!, $owner: String!, $requester: String!, $authToken: String!) {
            removeTeam(teamId: $teamId, owner: $owner, requester: $requester, authToken: $authToken) {
                removedTeam{
                _id
                owner
                teamName
                teamMembers {
                    email
                }
                }
                removedEvents{
                _id
                }
            }
        }
    `
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: REMOVE_TEAM_MUTATION,
        variables: { teamId, owner, requester, authToken }
    });
    return result;
}

const editPassword = async (requester, oldPassword, newPassword) => {
    //GRAPHQL QUERY:
    const EDIT_PASSWORD_MUTATION = gql`
        mutation ($requester: String!, $oldPassword: String!, $newPassword: String!){
        editPassword(requester: $requester, oldPassword: $oldPassword, newPassword: $newPassword)  }`
    //FETCHING DATA:
    let result = await client.mutate({
        mutation: EDIT_PASSWORD_MUTATION,
        variables: { requester, oldPassword, newPassword }
    });
    return result;
}

export { client, addUser, removeUser, getUser, getUserEvents, getUserTeams, editUserName, editUserRole, addTeamMember, removeTeamMember, addEvent, removeEvent, editEvent, addRecurrent, removeRecurrent, addEarning, addEarnValue, editEarning, removeEarning, addTeam, editTeamName, removeTeam, editPassword, generateJWT };