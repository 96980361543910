import React, { Component } from 'react';
import NoTeam from '../../components/NoTeam';
import memoize from 'memoize-one';
import YearSelector from './YearSelector';
import AddButton from './AddButton';
import Dropdown from '../Events/Dropdown';
import NewOldButton from '../Events/NewOld';
import MonthsChart from './MonthsChart';
import TeamsChart from './TeamsChart';
import EventsChart from './EventsChart';
import AverageEventType from './AverageEventType'
import Statistics from './Statistics';
import IncomeList from './IncomeList';
import YearsChart from './YearsChart';
import { addEarning } from '../../queries/queries';
import { db } from '../../queries/db';
import { addTempEarning } from '../../queries/temporary'

class Money extends Component {
    constructor(props) {
        super(props);
        this.state = {
            st: 'st',
            selectedYear: (new Date().getFullYear()),
            reRender: false,
            add: false,
            allYears: true,
            options: false,
            noEntry: true
        }
    }

    onYearClick = (e) => {
        let select = e.target;
        this.setState({ selectedYear: select.value })
    }

    onSelectedYear = () => {
        let select = document.getElementById('yearSelect');
        this.setState({ selectedYear: select.value });
    }

    reRender = () => {
        this.setState({ reRender: !this.state.reRender })
    }

    onAddButton = () => {
        this.setState({ add: !this.state.add })
    }

    onOptionClick = () => {
        this.setState({ options: !this.state.options })
    }

    onAllClick = () => {
        this.setState({ allYears: !this.state.allYears });
    }

    addEarning = async () => {
        const { startWorking, showErrors, userData } = this.props;
        let dateInput = document.getElementById('inputAddDate');
        let descriptionInput = document.getElementById('inputAddDescription');
        let sumInput = document.getElementById('inputAddSum');
        let teamInput = document.getElementById('selectNewEntry');
        let typeInput = document.getElementById('type');
        let authToken = userData.user.authToken;
        if (dateInput.value !== "" && sumInput.value !== '') {
            startWorking(true, `Adding earning...`);
            let foundTeam = userData.realTeams.find(team => { return team._id === teamInput.value; });
            let anyError = [];
            let earning = await addEarning(typeInput.value, dateInput.value, foundTeam.teamName, foundTeam.owner, '', '', 0, Number(sumInput.value), 0, descriptionInput.value, authToken).catch(err => { anyError[0] = err; console.log(err) });
            if (earning) {
                userData.realEarnings.push(earning.data.addEarning);
                await db.earnings.put(earning.data.addEarning);
                this.reRender();
                this.onAddButton();
                startWorking(false);
            } else {
                addTempEarning(typeInput.value, dateInput.value, foundTeam.teamName, foundTeam.owner, '', '', 0, Number(sumInput.value), 0, descriptionInput.value);
                startWorking(false);
                console.log(anyError);
                if (anyError[0].networkError) {
                    showErrors(true, ["Network error! Can't add this event. Please try again later!"]);
                } else {
                    showErrors(true, ["Error! Can't add this event. Please try again later!"])
                }
            }
        } else {
            sumInput.setAttribute('style', 'border-color: red');
            dateInput.setAttribute('style', 'border-color: red');
        }
    }
    //EXTRACT UNIQUE YEARS FROM ALL EVENTS:
    extractYears = (events) => {
        let years = [];
        events.forEach(event => {
            let year = new Date(event.date);
            if (!years.includes(year.getFullYear())) {
                years.push(year.getFullYear())
            }
        });
        return (years);
    }

    multipleYears = () => {
        let uniqueYears = this.extractYears(this.props.userData.realEarnings);
        if (uniqueYears.length > 1) { return true } else { return false };
    }

    getTotalEarningsOfYear = () => { //RETURNS THE SUM OF CURRENT YEAR EARNINGS
        function reduceFunction(total, sum) {
            return total + sum;
        }
        let events = this.getEventsByYear();
        let accumulator = [];
        events.forEach(event => {
            accumulator.push(Number(event.earned))
        })
        return accumulator.reduce(reduceFunction, 0);
    }

    getYears = () => {     //GET ORDERED UNIQUE YEARS OF EVENTS, LIMITED BY allYears OPTION
        let orderedEvents = this.props.userData.realEarnings;
        orderedEvents.sort((a, b) => {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return (dateA - dateB)
        });

        let years = !this.state.allYears ? this.extractYears(orderedEvents) : (
            this.extractYears(orderedEvents).filter(year => {
                return year <= new Date().getFullYear()
            })
        );
        return years;
    }

    getEventsByYear = () => { //RETURNS ARRAY WITH EVENTS SELECTED BY YEAR EX: EVENTS FROM 2018
        let eventsByYear = [];
        let orderedEvents = this.props.userData.realEarnings;
        orderedEvents.sort((a, b) => {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return (dateA - dateB)
        });

        orderedEvents.forEach(event => {
            let date = (new Date(event.date).getFullYear());

            if (Number(this.state.selectedYear) === date) {
                eventsByYear.push(event);
            }
        })
        let eventsFilter = memoize(events => events.filter((event) => {
            return new Date(event.date) < new Date()
        }))
        let eventsFiltered = eventsFilter(eventsByYear);

        if (this.state.allYears) {
            return eventsFiltered;
        } else {
            return eventsByYear;
        }
    }

    componentDidUpdate() {
        let selector = document.getElementById('yearSelect');
        if (this.state.noEntry) { //IF IS NO EVENT TO DISPLAY
            if (this.getEventsByYear().length === 0 && this.getYears().length > 0 && selector) {
                this.setState({
                    selectedYear: selector.value,
                    noEntry: !this.state.noEntry //USING THIS TO AVOID INFINITE RERENDER OF COMPONENT IF THER IS NO EVENT
                })
            }
        }
    }


    render() {
        const { userData, startWorking, showErrors } = this.props;
        const { selectedYear, add, allYears, options } = this.state;
        let dataByYear = this.getEventsByYear();
        return (
            <div>
                {userData.realTeams.length > 0 ? (
                    <>
                        <div>
                            <div className='flex'>
                                <div className='flex w-50 dtc'>
                                    <AddButton addClick={this.onAddButton} add={add} />
                                </div>
                                <div className='flex flex-wrap w-50'>
                                    <YearSelector years={this.getYears()} onYearClick={this.onSelectedYear} />
                                </div>
                                <Dropdown optionClick={this.onOptionClick} dropStyle={options} />
                            </div>
                            {options ? (
                                <div>
                                    <div className='flex'>

                                        <div className='ba b--near-white br3 bg-white o-90 flex w-100' onClick={this.optionClick}>
                                            <NewOldButton newOld={allYears ? 'ALL EVENTS' : 'CURRENT EARNINGS'} state={this.onAllClick} />
                                        </div>
                                    </div>
                                </div>
                            ) : (null)}
                        </div>
                        {add ? (
                            <div className='ba b--light-blue  pa1' >
                                <div className='flex center w-100 w-50-ns'>
                                    <input className='ma1 w-50' required type='date' id='inputAddDate' title='please select date'></input>
                                    <input className='ma1 w-50' required placeholder='earn' type='number' id='inputAddSum' title='please enter sum'></input>
                                </div>
                                <div className='flex center w-100 w-50-ns'>
                                    <input className='ma1 w-50' id='type' type='text' placeholder='type' list='types'></input>
                                    <datalist id='types'>
                                        {Array.from(new Set(this.props.userData.realEarnings.map(event => { return event.type }))).map(event => {
                                            return <option key={event}>{event}</option>
                                        })}
                                    </datalist>
                                    <select id='selectNewEntry' name="teams" className='tc dt ma1 dim pointer noselect fw9 center w-50'>
                                        {/* {<option value='otherteam' key='otherteam'>No Team</option>} */}
                                        {this.props.userData.realTeams.map(team => {
                                            return <option value={team._id} key={team._id} >{team.teamName} </option>
                                        })}
                                    </select>
                                </div>
                                <div className='flex center w-100 w-50-ns'>
                                    <div className='flex ma1 w-100'><textarea className='w-100' placeholder='description' id='inputAddDescription'></textarea></div>
                                </div>
                                <div className='flex center w-100 w-50-ns ma1 dt'><p className='dtc v-mid center pa3 ma0 bg-dark-gray light-blue grow dim pointer noselect br3 tc shadow-2' onClick={this.addEarning}>ADD</p></div>
                            </div>
                        ) : null}
                        {allYears ? (
                            <div className='gray f5'>Showing only past  earnings: </div>
                        ) : (
                                <div className='gray f5'>Showing all earnings:</div>
                            )}
                        {userData.realEarnings.length > 0 ? (
                            <div className='flex flex-wrap pa2 w-100 w-80-m w-80-l center'>
                                <IncomeList showErrors={showErrors} startWorking={startWorking} eventsByYear={dataByYear} userData={userData} reRender={this.reRender} key={selectedYear} onSelectedYear={this.onSelectedYear} />
                                <Statistics events={dataByYear} />
                                {this.getTotalEarningsOfYear() > 0 ? (
                                    <>
                                        <MonthsChart events={dataByYear} />
                                        <TeamsChart events={dataByYear} userData={userData} />
                                        <EventsChart events={dataByYear} />
                                        <AverageEventType events={dataByYear} />
                                        {this.multipleYears() ? (
                                            <YearsChart events={userData.realEarnings} allYears={allYears} reRender={this.reRender} />
                                        ) : null}
                                    </>
                                ) : <h4>Add earnings to create charts !</h4>}
                            </div>
                        ) : (<h1 className='tc'>No Earnings. Please add some !</h1>)}
                    </>
                ) : (
                        <NoTeam createClick={this.props.createClick} />

                    )}
            </div>
        )
    }
}
export default Money;