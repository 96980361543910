import React, { Component } from "react";
import NoTeam from '../../components/NoTeam';
import AddButton from "./AddButton";
import NewEntry from "./NewEntry";
import MultipleTables from "./MultipleTables";
import NewOldButton from "./NewOld";
import Details from './Details'
import CalendarDetails from './CalendarDetails';
import NewCalendarEntry from './NewCalendarEntry';
import TeamSelector from './TeamSelector';
import Dropdown from "./Dropdown";
import ClearRecurrent from "./ClearRecurrent";
import { addEvent, removeEvent, editEvent, addRecurrent, removeRecurrent, addEarning } from "../../queries/queries";
import { emitData } from '../../queries/socket';
import { db } from '../../queries/db';
import { addTempEvent, addTempEarning, addTempRecurrent, addTempEditedEvent, addTempRemovedEvent } from '../../queries/temporary';

let calendarEvents = [];
let eDetails;

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newEntry: false,
            newCalendarEntry: false,
            newList: true,
            details: false,
            calendarDetails: false,
            calendarSelection: '',
            nothing: '',
            selectedTeam: 'allteams',
            dropStyle: false,
            recurrent: false,
            recurrentType: false,
            updateComponent: false
        };
    }

    openNewEntry = () => {
        this.setState({
            newEntry: !this.state.newEntry,
            calendarDetails: false,
            calendarSelection: ''
        })
        calendarEvents = [];
    };

    closeNewEntry = () => {
        console.log('closeNewyEntry clicked');

        let type = document.getElementById('type');
        let date = document.getElementById('date');
        let city = document.getElementById('city');
        let location = document.getElementById('location');
        let price = document.getElementById('price');
        let earnest = document.getElementById('earnest');
        let description = document.getElementById('description');

        type.value = '';
        date.value = '';
        city.value = '';
        location.value = '';
        price.value = '';
        earnest.value = '';
        description.value = '';

        type.setAttribute('style', 'border-color: inherit');
        date.setAttribute('style', 'border-color: inherit');
        this.setState({
            recurrent: false,
            recurrentType: false,
            newEntry: false,
            newCalendarEntry: false,
            calendarSelection: ''
        })
    }
    addNewEvent = async () => {
        const { startWorking, showErrors, userData } = this.props;
        this.setState({
            calendarSelection: ''
        })
        //GETTING FIELDS:
        let type = document.getElementById('type');
        let date = document.getElementById('date');
        let city = document.getElementById('city');
        let location = document.getElementById('location');
        let price = document.getElementById('price');
        let earnest = document.getElementById('earnest');
        let description = document.getElementById('description');
        let select = document.getElementById('selectNewEntry');
        let author = userData.user.name;
        let authorEmail = userData.user.email;
        let created = new Date();
        let editAuthor = "";
        let editAuthorEmail = "";
        let modified = "";
        let anyError = [];
        let authToken = userData.user.authToken;
        //MUST DEFINE TEAM (BY ID), OWNER (BY EMAIL):
        if (type.value.length > 0 && date.value.length > 0 && select.value.length > 0) {
            startWorking(true, `Adding ${type.value.trim()}...`);
            let owner = select.options[select.selectedIndex].getAttribute("owner");
            let team = select.options[select.selectedIndex].id;
            //ADD EVENT:
            let event = await addEvent(type.value, date.value, team, owner, location.value, city.value, Number(price.value), 0, Number(earnest.value), description.value, created, author, authorEmail, editAuthor, editAuthorEmail, modified, authToken).catch(err => { anyError[0] = err; });
            if (event) {
                await db.events.put(event.data.addEvent);
                userData.realEvents.push(event.data.addEvent);
                emitData('addEvent', event.data.addEvent);
                startWorking(false);
                startWorking(true, 'Adding earning...');
                //ADD EARNING:
                let earning = await addEarning(type.value, date.value, select.options[select.selectedIndex].value, userData.user.email, location.value, city.value, Number(price.value), 0, Number(earnest), description.value, authToken).catch(err => { anyError[1] = err; console.log(err) });
                if (earning) {
                    await db.earnings.put(earning.data.addEarning);
                    userData.realEarnings.push(earning.data.addEarning);
                    startWorking(false);
                } else {
                    addTempEarning(type.value, date.value, select.options[select.selectedIndex].value, userData.user.email, location.value, city.value, Number(price.value), 0, Number(earnest), description.value);
                    startWorking(false);
                    console.log(anyError);
                    if (anyError[1].networkError) {
                        showErrors(true, ["Network error! Can't add this earning. Please try adding manually!"]);
                    } else {
                        showErrors(true, ["Error! Can't add this earning. Please try adding manually!"])
                    }
                }
                //ADD RECURRENT:
                if (this.state.recurrent) {
                    startWorking(true, `Adding recurrent...`);
                    let recurrent = await addRecurrent(type.value, date.value, team, userData.user.email, location.value, city.value, Number(price.value), 0, Number(earnest), description.value, authToken).catch(err => { anyError[2] = err; console.log(err) })
                    if (recurrent) {
                        userData.realRecurrent.push(recurrent.data.addRecurrent);
                        startWorking(false);
                    } else {
                        startWorking(false);
                        console.log(anyError);
                        if (anyError[2].networkError) {
                            showErrors(true, ["Network error! Can't add this recurrent. Please try next time!"]);
                        } else {
                            showErrors(true, ["Error! Can't add this recurrent. Please try next time!"])
                        }
                    }
                }
                //CLEAR THE FIELDS:
                type.value = '';
                date.value = '';
                city.value = '';
                location.value = '';
                price.value = '';
                earnest.value = '';
                description.value = '';

                type.setAttribute('style', 'border-color: inherit');
                date.setAttribute('style', 'border-color: inherit');
                this.setState({ recurrent: false, recurrentType: false })
                //CLOSE THE ENTRY:
                this.setState({
                    newCalendarEntry: false,
                    newEntry: false
                })
            } else {
                addTempEvent(type.value, date.value, team, owner, location.value, city.value, Number(price.value), 0, Number(earnest.value), description.value, created, author, authorEmail, editAuthor, editAuthorEmail, modified);
                addTempEarning(type.value, date.value, select.options[select.selectedIndex].value, userData.user.email, location.value, city.value, Number(price.value), 0, Number(earnest), description.value);
                if (this.state.recurrent) {
                    addTempRecurrent(type.value, date.value, team, userData.user.email, location.value, city.value, Number(price.value), 0, Number(earnest), description.value);
                }
                startWorking(false);
                // console.log(anyError);
                //CLOSE THE ENTRY:
                this.setState({
                    newCalendarEntry: false,
                    newEntry: false,
                    recurrent: false,
                    recurrentType: false
                })
                if (anyError[0].networkError) {
                    // showErrors(true, ["Network problems! Event stored TEMPORARY! Trying to save it next time connection is established."]);
                    this.updateApp('EVENT ADDED TEMPORARY IN OFFLINE MODE !');
                } else {
                    showErrors(true, ["Error! Can't add this event. Please try again later!"])
                }
            }
        } else {
            type.setAttribute('style', 'border-color: red');
            date.setAttribute('style', 'border-color: red');
            select.setAttribute('style', 'border-color: red');
            select.setAttribute('title', 'SELECT A TEAM OR CREATE NEW TEAM FIRST !');
        }
    }
    eventsRender = () => {
        this.setState({
            newList: !this.state.newList
        })
    }

    options = (e) => {
        eDetails = [];
        calendarEvents = [];
        this.setState({
            details: !this.state.details,
            calendarDetails: false
        })
        let id = e.target.parentNode.getAttribute('uid');

        this.props.userData.realEvents.forEach(event => {
            if (id === event._id) {
                eDetails.push(event);
            }
        })
    }

    closeDetails = () => {
        this.setState({
            details: !this.state.details
        })
    }

    deleteEvent = async (e) => {
        const { userData, startWorking, showErrors } = this.props;
        this.setState({ calendarSelection: '' });
        startWorking(true, 'Deleting event...');
        let id = e.target.getAttribute('uid');
        let teamId = userData.realEvents.find(event => { return event._id === id }).team;
        let anyError = [];
        let authToken = userData.user.authToken;
        let removedEventId = await removeEvent(userData.user.email, id, teamId, authToken).catch(err => { anyError[0] = err; });
        if (removedEventId) {
            await db.events.delete(removedEventId.data.removeEvent._id);
            userData.realEvents.forEach((event, index) => {
                if (removedEventId.data.removeEvent._id === event._id) {
                    userData.realEvents.splice(index, 1);
                    this.setState({
                        details: !this.state.details
                    })
                }
            })
            emitData('removeEvent', {
                event: removedEventId.data.removeEvent,
                requester: userData.user
            });
            startWorking(false);
        } else {
            let eventToRemove = userData.realEvents.find(event => { return event._id === id });
            addTempRemovedEvent(eventToRemove);
            this.setState({
                details: false
            })
            startWorking(false);
            // console.log(anyError);
            if (anyError[0].networkError) {
                // showErrors(true, ["Network error! Can't delete this event. Please try again later!"]);
                this.updateApp('EVENT REMOVED TEMPORARY IN OFFLINE MODE !');
            } else {
                showErrors(true, ["Can't delete this event. Please try again later!"])
            }
        }
    }

    saveEdited = async (e) => {
        const { userData, startWorking, showErrors } = this.props;
        let id = e.target.getAttribute('uid');
        let type = document.getElementById('editType').value.toUpperCase();
        let date = document.getElementById('editDate').value;
        let team = document.getElementById('selectEditEntry').value;
        let owner = (userData.realEvents.find(event => { return event._id === id })).owner;
        let location = document.getElementById('editLocation').value;
        let city = document.getElementById('editCity').value;
        let price = document.getElementById('editPrice').value;
        let earnest = document.getElementById('editEarnest').value;
        let description = document.getElementById('editDescription').value;
        let authorEmail = (userData.realEvents.find(event => { return event._id === id })).authorEmail;
        let editAuthor = userData.user.name;
        let editAuthorEmail = userData.user.email;
        let modified = new Date();
        let authToken = userData.user.authToken;
        if (type.length > 0 && date.length > 0) {
            startWorking(true, 'Saving edited...');
            let anyError = [];
            let editedEventData = await editEvent(id, type, date, team, owner, location, city, Number(price), Number(earnest), description, authorEmail, editAuthor, editAuthorEmail, modified, authToken).catch(err => { anyError[0] = err; });
            if (editedEventData) {
                let editedEvent = editedEventData.data.editEvent;
                this.props.userData.realEvents.forEach(event => {
                    if (event._id === editedEvent._id.toString()) {
                        event.type = editedEvent.type;
                        event.date = editedEvent.date;
                        event.team = editedEvent.team;
                        event.owner = editedEvent.owner;
                        event.location = editedEvent.location;
                        event.city = editedEvent.city;
                        event.price = editedEvent.price;
                        event.earnest = editedEvent.earnest;
                        event.description = editedEvent.description;
                        event.authorEmail = editedEvent.authorEmail;
                        event.editAuthor = editedEvent.editAuthor;
                        event.editAuthorEmail = editedEvent.editAuthorEmail;
                        event.author = editedEvent.author;
                        event.authorEmail = editedEvent.authorEmail;
                        event.modified = editedEvent.modified;
                        this.setState({
                            details: false
                        })
                    }
                });
                await db.events.put(editedEvent);
                emitData('editEvent', editedEventData.data.editEvent);
                startWorking(false);
            } else {
                await addTempEditedEvent(id, type, date, team, owner, location, city, Number(price), Number(earnest), description, authorEmail, editAuthor, editAuthorEmail, modified);
                this.setState({
                    details: false
                })
                startWorking(false);
                // console.log(anyError);
                if (anyError[0].networkError) {
                    // showErrors(true, ["Network error! Can't edit this event. Please try again later!"]);
                    this.updateApp('EVENT EDITED TEMPORARY IN OFFLINE MODE !');
                } else {
                    if (anyError[0].message === 'GraphQL error: jwt expired') {
                        showErrors(true, ['FOR SECURITY REASONS LOG IN AGAIN PLEASE!']);
                        window.myApp.onExitClick();
                    } else {
                        showErrors(true, ["Can't edit this event. Please try again later!"]);
                    }
                }
            }
        }
        else {
            document.getElementById('editType').setAttribute('style', 'border-color: red');
            document.getElementById('editDate').setAttribute('style', 'border-color: red');
        }
    }

    convertCalendarDate = (date) => {
        let d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();

        return (`${year}, ${month + 1}, ${day}`)
    }

    dateClicked = (e) => {
        calendarEvents = [];

        function convertCalendarDate(date) {
            let d = new Date(date);
            let year = d.getFullYear();
            let month = d.getMonth();
            let day = d.getDate();
            if (month + 1 < 10 && day < 10) {
                return (`${year}-0${month + 1}-0${day}`)
            }
            if (month + 1 < 10 && day >= 10) {
                return (`${year}-0${month + 1}-${day}`)
            }
            if (month + 1 >= 10 && day < 10) {
                return (`${year}-${month + 1}-0${day}`)
            }
            if (month + 1 >= 10 && day >= 10) {
                return (`${year}-${month + 1}-${day}`)
            }

        }
        this.setState({
            calendarDetails: !this.state.calendarDetails,
            calendarSelection: convertCalendarDate(new Date(e))
        });
        this.props.userData.realEvents.forEach(event => {
            if (this.convertCalendarDate(e) === this.convertCalendarDate(new Date(event.date))) {
                calendarEvents.push(event);
            }
        })
    }
    closeCalendarDetails = () => {
        this.setState({
            calendarSelection: '',
            calendarDetails: !this.state.calendarDetails,
        });
        calendarEvents = [];
    }

    openNewCalendarEntry = () => {
        this.setState({
            newCalendarEntry: true,
            calendarDetails: false
        });
    }
    closeNewCalendarEntry = () => {
        this.setState({ newCalendarEntry: false })
    }

    onTeamClick = (e) => {
        this.setState({
            selectedTeam: e.target.value
        });
    }

    filterFunction = (event) => {
        if (this.state.selectedTeam === 'allteams') {
            return event.type.length > 0;
        }

        return event.team === this.state.selectedTeam;
    }

    optionClick = () => {
        this.setState({ dropStyle: !this.state.dropStyle })
    }

    isRecurrent = () => {
        this.setState({ recurrent: !this.state.recurrent });
    }
    onTypeButtonClick = () => {
        let type = document.getElementById('type');
        let city = document.getElementById('city');
        let location = document.getElementById('location');
        let price = document.getElementById('price');
        let earnest = document.getElementById('earnest');
        let description = document.getElementById('description');
        let select = document.getElementById('selectNewEntry');

        type.value = '';
        city.value = '';
        location.value = '';
        price.value = '';
        earnest.value = '';
        description.value = '';
        select.value = '';

        this.setState({ recurrentType: !this.state.recurrentType })
    }

    onRecurrentEventSelect = () => {

        let type = document.getElementById('type');
        let city = document.getElementById('city');
        let location = document.getElementById('location');
        let price = document.getElementById('price');
        let earnest = document.getElementById('earnest');
        let description = document.getElementById('description');
        let select = document.getElementById('selectNewEntry');

        let selectedtype = type.options[type.selectedIndex].value;
        this.props.userData.realRecurrent.forEach(event => {
            if (event.type === selectedtype) {
                let team = this.props.userData.realTeams.find(team => { return team._id === event.team }); //GET TEAM BY EVENT'S TEAM ID

                city.value = event.city;
                location.value = event.location;
                price.value = event.price;
                earnest.value = event.earnest;
                description.value = event.description;
                select.value = team ? team.teamName : ""; //SETTING SELECT'S VALUE WITH TEAM'S TEAMNAME
            }

        })
        if (selectedtype === 'nothing') {
            city.value = '';
            location.value = '';
            price.value = '';
            earnest.value = '';
            description.value = '';
            select.value = '';
            console.log(selectedtype);
        }
    }

    onClearRecurrentClick = async () => {
        const { userData, startWorking, showErrors } = this.props;
        startWorking(true, 'Clearing recurrents...');
        let anyError = [];
        let authToken = userData.user.authToken;
        let done = await removeRecurrent(userData.user.email, authToken).catch(err => { anyError[0] = err; console.log(err) });
        if (done) {
            userData.realRecurrent = [];
            startWorking(false);
        } else {
            startWorking(false);
            console.log(anyError);
            if (anyError[0].networkError) {
                showErrors(true, ["Network error! Can't clear recurrent. Please try again later!"]);
            } else {
                showErrors(true, ["Can't clear recurrent. Please try again later!"])
            }
        }
    }

    updateApp = (message) => {
        const { updateComponent, details, newEntry, newCalendarEntry } = this.state;
        if (!details && !newEntry && !newCalendarEntry) {
            this.setState({ updateComponent: !updateComponent });
            if (message) {
                this.props.showConfirmation(true, message);
            }
        }
    }

    componentDidMount() {
        const { userData } = this.props;
        if (this.state.selectedTeam === 'allteams') {
            if (userData.realTeams.length === 1) {
                this.setState({ selectedTeam: userData.realTeams[0]._id })
            }
        }
    }
    render() {
        const { newEntry, newList, details, calendarDetails, calendarSelection, newCalendarEntry, selectedTeam, dropStyle, recurrent, recurrentType } = this.state;
        const { userData } = this.props;
        let invisibleStyle = 'display-none dropSami';
        let visibleStyle = 'ba b--near-white br3 bg-white o-90 flex w-100 dt';
        return (
            <div className=''>
                {userData.realTeams.length > 0 ? (<>
                    <div className='flex'>
                        <div className='flex w-50 dtc'>
                            <AddButton newEvent={this.openNewEntry} />
                        </div>
                        <div className='flex flex-wrap w-50'>
                            <TeamSelector teams={userData.realTeams} teamClick={this.onTeamClick} />
                        </div>
                        <Dropdown dropStyle={dropStyle} optionClick={this.optionClick} newOld2={newList ? 'all years' : 'this year'} state={this.eventsRender} />
                    </div>
                    <div className='flex'>

                        <div className={this.state.dropStyle ? visibleStyle : invisibleStyle} onClick={this.optionClick}>
                            <NewOldButton newOld={newList ? 'ALL YEARS' : 'THIS YEAR'} state={this.eventsRender} />
                            {userData.realRecurrent.length > 0 ? <ClearRecurrent state={this.onClearRecurrentClick} /> : null}
                        </div>
                    </div>
                    <div>
                        {newEntry ? <NewEntry recurrent={recurrent} isRecurrent={this.isRecurrent} recurrentType={recurrentType} onTypeButtonClick={this.onTypeButtonClick} onRecurrentEventSelect={this.onRecurrentEventSelect} events={userData.realEvents} display={newEntry} teams={userData.realTeams} userData={userData} stateTeam={selectedTeam} closeDiv={this.closeNewEntry} addNewEvent={this.addNewEvent} /> : null}
                        {newCalendarEntry ? <NewCalendarEntry recurrent={recurrent} isRecurrent={this.isRecurrent} recurrentType={recurrentType} onTypeButtonClick={this.onTypeButtonClick} onRecurrentEventSelect={this.onRecurrentEventSelect} events={userData.realEvents} display={newEntry} teams={userData.realTeams} userData={userData} stateTeam={selectedTeam} currentDate={calendarSelection} closeDiv={this.closeNewEntry} addNewEvent={this.addNewEvent} /> : null}
                        {details ? <Details event={eDetails} teams={userData.realTeams} stateTeam={selectedTeam} closeDiv={this.closeDetails} del={this.deleteEvent} saveEvent={this.saveEdited} /> : []}
                        {calendarDetails ? <CalendarDetails close={this.closeCalendarDetails} events={calendarEvents} options={this.options} newEvent={this.openNewCalendarEntry} /> : null}
                        <MultipleTables events={userData.realEvents.filter(this.filterFunction)} newOld={newList} options={this.options} dateClicked={this.dateClicked} userData={userData} />
                    </div> </>
                ) : (
                        <NoTeam createClick={this.props.createClick} />
                    )}
            </div>
        );
    }
}
export default Events;