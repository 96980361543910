import React from 'react';

const EditTeamName = ({ editTeamName, team }) => {
    function onEnterKey(e) {
        if (e.keyCode === 13) editTeamName(e);
    }
    return (
        <div className='ma1'>
            <input className='ma2 pa2' id='newteamname' placeholder={team.teamName} onKeyDown={onEnterKey} /> <button onClick={editTeamName}>Change</button>
        </div>
    )
}
export default EditTeamName;