import React, { PureComponent } from 'react';
import { Pie } from 'react-chartjs-2';

const data =
{
    labels: [],
    datasets: [
        {
            label: 'Income',
            backgroundColor: ['#a3ff8f', '#ff691f', '#00cfd6', '#52bdff', '#a3ff8f', '#ff691f', '#00cfd6', '#52bdff', '#a3ff8f', '#ff691f', '#00cfd6', '#52bdff'],
            borderColor: 'white',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: []
        }
    ]
}

class TeamsChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: data
        }
    }

    createData = (events) => {
        function reduceFunction(total, sum) {
            return total + sum;
        }
        let teams = [];
        events.forEach(event => {
            teams.push(event.team)
        })
        let uniqueTeams = Array.from(new Set(teams));

        data.labels = uniqueTeams;
        data.datasets[0].data = [];

        data.labels.forEach((team, i) => {
            let tempArr = [];
            events.forEach(event => {
                if (team === event.team) {
                    tempArr.push(Number(event.earned));
                }
            })
            if (tempArr.length > 0) {
                data.datasets[0].data[i] = tempArr.reduce(reduceFunction, 0);
            } else data.datasets[0].data[i] = 0;
        })
    }

    render() {
        const { events } = this.props;
        this.createData(events);
        return (
            <div className='w-100 mt3 bb'>
                <h3 className='tc'>Earning by team </h3>
                <Pie
                    height={125}
                    data={this.state.data}
                    redraw={true}
                />
            </div>
        )
    }
}
export default TeamsChart;