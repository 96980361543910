import Dexie from 'dexie';

const db = new Dexie('RealDB');
const tempDb = new Dexie('TempDB');

db.version(1).stores({
    user: 'id',
    events: '_id, team',
    teams: '_id',
    recurrent: '_id',
    earnings: '_id'
});

tempDb.version(1).stores({
    editedUser: 'id',
    addedEvents: '_id, team',
    editedEvents: '_id',
    removedEvents: '_id',
    addedRecurrent: '_id',
    addedEarnings: '_id',
    editedEarnings: '_id',
    removedEarnings: '_id',
    addedTeams: '_id',
    editedTeams: '_id',
    removedTeams: '_id'
})

const createRealDB = async (user, events, teams, recurrent, earnings) => {
    // await db.open();
    await db.user.put(user)
    await db.events.bulkPut(events);
    await db.teams.bulkPut(teams);
    await db.recurrent.bulkPut(recurrent);
    await db.earnings.bulkPut(earnings);
}

// const createTempDB = async (editedUser, addedEvents, editedEvents, removedEvents, addedEarnings, editedEarnings, removedEarnings, addedTeams, editedTeams, removedTeams) => {
//     await tempDb.open();
//     await tempDb.editedUser.put(editedUser);
//     await tempDb.addedEvents.bulkPut(addedEvents);
//     await tempDb.editedEvents.bulkPut(editedEvents);
//     await tempDb.removedEvents.bulkPut(removedEvents);
//     await tempDb.addedEarnings.bulkPut(addedEarnings);
//     await tempDb.editedEarnings.bulkPut(editedEarnings);
//     await tempDb.removedEarnings.bulkPut(removedEarnings);
//     await tempDb.addedTeams.bulkPut(addedTeams);
//     await tempDb.editedTeams.bulkPut(editedTeams);
//     await tempDb.removedTeams.bulkPut(removedTeams);
// }

// const removeTempDB = async () => {
//     await tempDb.open();
//     await tempDb.delete();
// }

const removeRealDB = async () => {
    await db.open();
    await db.delete();
}

export { db, createRealDB, removeRealDB, tempDb }; 