import React, { useEffect, useState } from 'react';
// import { Offline, Online } from "react-detect-offline";

const Status = ({ message }) => {
    const [ online, setOnline ] = useState(window.navigator ? window.navigator.onLine : false)

    const onOnlineState = () => {
        window.location.reload(true)
        setOnline(true)
    }

    const onOfflineState = () => {
        setOnline(false)
    }

    useEffect(() => {
        window.addEventListener('online', onOnlineState)
        window.addEventListener('offline', onOfflineState)

        return () => {
            window.removeEventListener('online', onOnlineState)
            window.removeEventListener('offline', onOfflineState)
        }
    }, [])
    return (
        <>
            {
                online ?
                <p className='statusStyle' >{message}</p>
                :
                <p className='statusStyle' >OFFLINE</p>
            }
            {/* <Online onChange={() => { window.location.reload(true) }}> */}
            {/* <Online>
                <p className='statusStyle' >{message}</p>
            </Online>
            <Offline><p className='statusStyle' >OFFLINE</p></Offline> */}
        </>
    )
}

export default Status;