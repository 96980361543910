import React from 'react';

const LoginRegister = ({ clickLogin, clickRegister }) => {

    return (
        <nav className='flex'>
            <div className='ba br3 w-50 tc pa2 ma1 f3 f3-ns dim pointer bg-light-blue white shadow-2 noselect' onClick={clickLogin} ><div className='grow f4 f3-ns'>Login</div></div>
            <div className='ba br3 w-50 tc pa2 ma1 f3 f3-ns dim pointer bg-lightest-blue white shadow-2 noselect' onClick={clickRegister}><div className='grow f4 f3-ns'>Register</div></div>
        </nav>
    )
}

export default LoginRegister;