import React from 'react';
import LoginRegister from './LoginRegister';
import ImageBackground from './ImageBackground';
import smallPencilPicture from '../pictures/PencilBackground640x960.jpg';
import bigPencilPicture from '../pictures/PencilsBackground1280x1920.jpg';

const RegisterForm = ({ blabla, blabla2, onRegisterSubmit }) => {
    return (
        <div className=''>
            <LoginRegister clickLogin={blabla} clickRegister={blabla2} />
            <ImageBackground
                imageUrl={window.screen.width >= 992 ? (bigPencilPicture) : (smallPencilPicture)}
                cssClass='imageBackground o-60 h-100 w-100 center'
            />

            <form action="sign-up_submit" method="get" acceptCharset="utf-8" className='measure center pa1 pa5-ns ma3 ba w-80-ns w-90 shadow-1'>
                <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
                    <legend className="f4 fw6 ph0 mh0 center">Register</legend>

                    <div className="mt3">
                        <label className="db fw6 lh-copy f6" >Username</label>
                        <input
                            className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100"
                            type="text"
                            name="firstName"
                            id='registerUser'
                            required
                        />
                    </div>

                    <div className="mt3">
                        <label className="db fw6 lh-copy f6" htmlFor="email-address">Email address</label>
                        <input
                            className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100 measure"
                            type="email"
                            name="email-address"
                            id="registerEmail"
                            required
                        />
                    </div>

                    <div className="mt3">
                        <label className="db fw6 lh-copy f6" >Role</label>
                        <input
                            className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100"
                            type="text"
                            name="role"
                            placeholder="saxophone, dj, photographer, dancer..."
                            id="registerRole"
                            required
                        />
                    </div>

                    <div className="mt3">
                        <label className="db fw6 lh-copy f6" htmlFor="password">Password</label>
                        <input
                            className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white"
                            type="password"
                            name="password1"
                            id="registerPassword1"
                            required
                        />
                    </div>
                    <div className="mt3">
                        <label className="db fw6 lh-copy f6" htmlFor="password">Confirm password</label>
                        <input
                            className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white"
                            type="password"
                            name="password2"
                            id="registerPassword2"
                            required
                        />
                    </div>
                </fieldset>
                <div className='center pa0 noselect'>
                    <div onClick={onRegisterSubmit} className="b ph3 pv2 ba b--black bg-transparent grow pointer f6 dib ma0 center noselect">Create account</div>
                </div>
            </form>
        </div>
    )
}
export default RegisterForm;