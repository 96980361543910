import React, { Component } from 'react';
import Calendar from 'react-calendar';
import listIcon from '../../pictures/list.svg';
import calendarIcon from '../../pictures/calendar.svg';

class CreateTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendar: false,
            eventsLenght: this.props.userData.realEvents.length
        }
    }

    convertDate = (date) => {
        let d = new Date(date);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        return (`${months[month]} ${day} ${year}`)
    }

    convertCalendarDate = (date) => {
        let d = new Date(date);
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        return (`${year}, ${month + 1}, ${day}`)
    }

    changeNewOld = () => {
        if (this.props.newOld && (new Date(this.props.events[0].date).getFullYear()) < (new Date()).getFullYear()) { return 'mb1 display-none' }
        else { return 'mb1' }
    }

    onViewClick = () => {
        this.setState({ calendar: !this.state.calendar })
    }

    defineRowStyle = (date, id) => {
        let style = [''];
        if ((new Date(date)) < (new Date())) {
            style[0] += 'o-60';
        } else {
            style[0] += 'o-90';
        }

        if (id.indexOf('temp') > -1) {
            style[0] += ' eventsTableRed';
        } else {
            style[0] += ' eventsTable';
        }
        return style[0];
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.userData.realEvents.length !== this.props.userData.realEvents.length) {
            this.setState({ eventsLenght: nextProps.userData.realEvents.length })
            console.log('difference noticed!')
        }
    }

    render() {
        const { calendar } = this.state;
        let tdStyle = ' w-third w-third-m w-25-ns f5-ns f5 grow pointer pt2 pb2 fw1';
        let tdCityStyle = ' f5 w-third w-25-ns f5-ns fw1';
        let tdNoStyle = 'fw9 f7 gray pl0 pr0 dtc'
        let tdLocationStyle = ' f5-ns w-25 location-display-none';
        let thStyle = 'display-none';
        let thNoStyle = 'pa0 f7 gray display-none';
        let thLocationStyle = 'pa3 location-display-none display-none';
        let divStyle = this.changeNewOld();
        let tempEvents = this.props.events;

        tempEvents.sort((a, b) => {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return (dateA - dateB)
        });
        return (
            <div className={divStyle}>
                <div className='flex dtc noselect'>
                    <div className='w-third dim  center tc dt pa2 i pointer grow f6 noselect mt1 mb0 br3' onClick={this.onViewClick}>{calendar ? <img className="pointer noselect" src={listIcon} onClick={this.onOptionsClick} title='LIST' alt=''></img> : <img className="pointer noselect" src={calendarIcon} onClick={this.onOptionsClick} title='CALENDAR' alt=''></img>} </div>
                </div>
                {calendar ?
                    (
                        <Calendar
                            className='center mt1 bw1 b--dark-gray'
                            activeStartDate={new Date((new Date(tempEvents[0].date).getFullYear()), (new Date().getMonth()), 1, 1, 1, 1, 0)}
                            tileClassName={({ date, view }) => view === 'month' && tempEvents.every(event => this.convertCalendarDate(new Date(event.date)) !== this.convertCalendarDate(date)
                            ) ? null : 'bg-light-blue br4 '}
                            minDetail='year'
                            maxDate={new Date((new Date(tempEvents[0].date).getFullYear()), 11, 31, 1, 1, 1, 0)}
                            minDate={new Date((new Date(tempEvents[0].date).getFullYear()), 0, 1, 1, 1, 1, 0)}
                            showNeighboringMonth={false}
                            onClickDay={(e) => this.props.dateClicked(e)}
                        />
                    )
                    :
                    (
                        <table className=' mb1 pb1 w-100 tc dt b--gray b--dark-blue br2 pa2'>
                            <tbody className=''>
                                <tr className='w-100'>
                                    <th className={thNoStyle}></th>
                                    <th className={thStyle}></th>
                                    <th className={thStyle}></th>
                                    <th className={thStyle}></th>
                                    {/* <th className={thLocationStyle}>Location</th> */}
                                    <th className={thLocationStyle}></th>
                                </tr>
                                {
                                    this.props.events.map((event, i) => (
                                        <tr
                                            key={event._id}
                                            // className={(new Date(event.date)) < (new Date()) ? ('o-60 eventsTable') : ('o-90 eventsTable')}
                                            className={this.defineRowStyle(event.date, event._id)}
                                            uid={event._id}
                                        >
                                            <td className={tdNoStyle}><div className='pa0 ma0 noselect'>{i + 1}</div> </td>
                                            <td className={tdStyle} onClick={this.props.options}>{this.convertDate(event.date)}</td>
                                            <td className={tdStyle} onClick={this.props.options}>{event.type}</td>
                                            <td className={tdCityStyle}>{event.city}</td>
                                            {/* <td className={tdLocationStyle}>{event.location}</td> */}
                                            <td className={tdLocationStyle}>{this.props.userData.realTeams.find(team => {
                                                return team._id === event.team
                                            }).teamName}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    )}
            </div>
        )
    }
}

export default CreateTable;